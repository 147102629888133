import React from "react";
import LoadingImage from "../assets/loader.gif";

const Loading = () => (
	<div className="loader">
		<img className="loader__image" alt="loading" src={LoadingImage} />
	</div>
);

export default Loading;
