import { useContext } from "react";

import { TeamContext } from "./TeamAuth";

export default function Avatar({ uid, name, type }) {
	const teamMates = useContext(TeamContext);
	// console.log("avatar", uid);
	//console.log("avatar team", teamMates2);
	const user = teamMates.find((x) => x.id === uid);
	// console.log("avatar src", src);
	return (
		<>
			<div className={`avatar ${type && type}`}>
				<img src={user.photoURL} alt="user avatar" />
			</div>
			{name && <div className="userName">{user.displayName} </div>}
		</>
	);
}
