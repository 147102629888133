import { firebase } from "../../firebase/config";
import { Link } from "react-router-dom";

// styles
import { useState } from "react";
import "./Login.css";

// hooks and components
import { useLogin } from "../../hooks/useLogin";

export default function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [forgottenPassword, setForgottenPassword] = useState(false);
	const [formMessage, setFormMessage] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	// hook
	const { login, error, isPending } = useLogin();

	const handleReset = () => {
		firebase
			.auth()
			.sendPasswordResetEmail(email)
			.then(() => {
				setPasswordError("");
				console.log("reset email sent");
				setFormMessage("Password email sent!");

				// Password reset email sent!
				// ..
			})
			.catch((error) => {
				console.log("reset", error);
				setPasswordError(error.message);
				// var errorCode = error.code;
				// var errorMessage = error.message;
				// ..
			});
	};

	//console.log(forgottenPassword);

	const handleSubmit = (e) => {
		console.log("running submit");
		e.preventDefault();
		if (!forgottenPassword) {
			setPasswordError(false);
			login(email, password);
		}
	};

	return (
		<>
			<form className="auth-form" onSubmit={handleSubmit}>
				<h2>Login</h2>
				<label>
					<span>email:</span>
					<input
						required
						type="email"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
					/>
				</label>
				{!forgottenPassword && (
					<label>
						<span>password:</span>
						<input
							required
							type="password"
							onChange={(e) => setPassword(e.target.value)}
							value={password}
						/>
					</label>
				)}

				{!isPending && !forgottenPassword && (
					<button className="btn">Login</button>
				)}
				{isPending && !forgottenPassword && (
					<button disabled className="btn">
						Loading
					</button>
				)}
				{error && !forgottenPassword && (
					<div className="error">{error}</div>
				)}
				{passwordError && <div className="error">{passwordError}</div>}
				{formMessage && forgottenPassword && (
					<div className="success">{formMessage}</div>
				)}
				{forgottenPassword && (
					<button className="btn" onClick={handleReset}>
						Reset Password
					</button>
				)}
				{!forgottenPassword && (
					<>
						<p
							className="message"
							onClick={() => setForgottenPassword(true)}
						>
							Forgot Password?
						</p>
						<Link to="/create">Create an account</Link>
					</>
				)}
				{forgottenPassword && (
					<p
						className="message"
						onClick={() => setForgottenPassword(false)}
					>
						Return to login
					</p>
				)}
			</form>
		</>
	);
}
