import { ownerWindow } from "@mui/material";
import numeral from "numeral";
import Avatar from "../Avatar";
import Number from "../../components/Number";
import moment from "moment";

export const mainColumns = [
	{
		Header: "General",
		columns: [
			{
				id: "Position",
				Header: "Position",
				accessor: "name", // accessor is the "key" in the data
				Cell: (props) => <div className="color1">{props.value}</div>,
			},
			{
				Header: "Amount",
				id: "Amount",
				accessor: "totalAmount", // accessor is the "key" in the data
				Cell: (props) => <div className="color1 ">{props.value}</div>,
			},
			{
				Header: "Avg Cost",
				id: "AvgCost",
				accessor: "averageCost", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div className="color1">
						{numeral(props.value).format("$0,0.000")}
					</div>
				),
			},
			// {
			// 	Header: "CreatedAt",
			// 	accessor: "createdAt", // accessor is the "key" in the data
			// 	Cell: (props) => (
			// 		<div>{props.value.toDate().toDateString()}</div>
			// 	),
			// },
		],
	},
	{
		Header: "Market Info",
		columns: [
			{
				Header: "Latest",
				id: "Latest",
				accessor: "latestPrice", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div>{numeral(props.value).format("$0,0.000")}</div>
				),
			},
			{
				Header: "Change",
				accessor: "changePercent", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div>
						<Number value={props.value} percent={true} />
					</div>
				),
			},
			{
				Header: "Updated",
				id: "Updated",
				accessor: "latestUpdate", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div className="small green">
						{/* {props.value
							? numeral(props.value).format("(%0,0.000)")
							: "NA"} */}
						{/* {props.value} */}
						{moment(props.value).fromNow()}
						{/* {new Date(props.value).toLocaleString("en-US")} */}
						{/* {new Date(props.value * 1000).toDateString()} */}
					</div>
				),
			},
		],
	},
	{
		Header: "Account",
		columns: [
			{
				Header: "Initial Value",
				id: "InitialValue",
				accessor: "initialTotalValue", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div> {numeral(props.value).format("$0,0.00")}</div>
				),
			},
			{
				Header: "Current Value",
				id: "CurrentValue",
				accessor: "currentTotalValue", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div> {numeral(props.value).format("$0,0.00")}</div>
				),
			},
			{
				Header: "Gain / Loss",
				id: "gainLoss",
				accessor: "gainLoss", // accessor is the "key" in the data
				Cell: (props) => (
					<div className={props.value > 0 ? "green" : "red"}>
						{" "}
						{props.value
							? numeral(props.value).format("($0,0.00)")
							: "NA"}
					</div>
				),
			},
			{
				Header: "%",
				id: "gainLossPercent",
				accessor: "gainLossPercent", // accessor is the "key" in the data
				Cell: (props) => (
					<div className={props.value > 0 ? "green" : "red"}>
						{props.value
							? numeral(props.value).format("(%0,0.000)")
							: "NA"}
					</div>
				),
			},
			{
				Header: "Owners",
				accessor: "ownerTotals", // accessor is the "key" in the data
				Cell: (props) => (
					<div className="">
						{props.value.map((owner) => (
							<Avatar uid={owner.uid} key={owner.uid} />
						))}
					</div>
				),
			},
		],
	},
];

export const ProfileColumns = [
	{
		Header: "Position",
		columns: [
			{
				Header: "Name",
				accessor: "name", // accessor is the "key" in the data
				Cell: (props) => <div className="color1">{props.value}</div>,
			},
			{
				Header: "Amount",
				accessor: "totalAmount", // accessor is the "key" in the data
				Cell: (props) => <div className="color1">{props.value}</div>,
			},
			{
				Header: "averageCost",
				accessor: "averageCost", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div className="color1">
						{numeral(props.value).format("$0,0.000")}
					</div>
				),
			},
			// {
			// 	Header: "CreatedAt",
			// 	accessor: "createdAt", // accessor is the "key" in the data
			// 	Cell: (props) => (
			// 		<div>{props.value.toDate().toDateString()}</div>
			// 	),
			// },
		],
	},
	{
		Header: "Market Info",
		columns: [
			{
				Header: "latestPrice",
				accessor: "latestPrice", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div>{numeral(props.value).format("$0,0.000")}</div>
				),
			},
			{
				Header: "changePercent",
				accessor: "changePercent", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div className={props.value > 0 ? "green" : "red"}>
						{/* {props.value
							? numeral(props.value).format("(%0,0.000)")
							: "NA"} */}
						{numeral(props.value).format("(%0,0.000)")}
					</div>
				),
			},
		],
	},
	{
		Header: "Account",
		columns: [
			{
				Header: "initialValue",
				accessor: "initialTotalValue", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div> {numeral(props.value).format("$0,0.00")}</div>
				),
			},
			{
				Header: "currentTotalValue",
				accessor: "currentTotalValue", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div> {numeral(props.value).format("$0,0.00")}</div>
				),
			},
			{
				Header: "gainLoss",
				accessor: "gainLoss", // accessor is the "key" in the data
				Cell: (props) => (
					<div className={props.value > 0 ? "green" : "red"}>
						{" "}
						{props.value
							? numeral(props.value).format("($0,0.00)")
							: "NA"}
					</div>
				),
			},
			{
				Header: "gainLossPercent",
				accessor: "gainLossPercent", // accessor is the "key" in the data
				Cell: (props) => (
					<div className={props.value > 0 ? "green" : "red"}>
						{props.value
							? numeral(props.value).format("(%0,0.000)")
							: "NA"}
					</div>
				),
			},
			// {
			// 	Header: "Owners",
			// 	accessor: "ownerTotals", // accessor is the "key" in the data
			// 	Cell: (props) => (
			// 		<div className="">
			// 			{props.value.map((owner) => (
			// 				<Avatar uid={owner.uid} />
			// 			))}
			// 		</div>
			// 	),
			// },
		],
	},
];

export const ProfileMobileColumns = [
	{
		Header: "Position",
		columns: [
			{
				Header: "Name",
				accessor: "name", // accessor is the "key" in the data
				Cell: (props) => <div className="color1">{props.value}</div>,
			},
			{
				Header: "Amount",
				accessor: "totalAmount", // accessor is the "key" in the data
				Cell: (props) => <div className="color1">{props.value}</div>,
			},
			// {
			// 	Header: "averageCost",
			// 	accessor: "averageCost", // accessor is the "key" in the data
			// 	//	Cell: (props) => <div>{props.value}</div>,
			// 	Cell: (props) => (
			// 		<div className="color1">
			// 			{numeral(props.value).format("$0,0.000")}
			// 		</div>
			// 	),
			// },
			// {
			// 	Header: "CreatedAt",
			// 	accessor: "createdAt", // accessor is the "key" in the data
			// 	Cell: (props) => (
			// 		<div>{props.value.toDate().toDateString()}</div>
			// 	),
			// },
		],
	},
	{
		Header: "Market Info",
		columns: [
			// {
			// 	Header: "latestPrice",
			// 	accessor: "latestPrice", // accessor is the "key" in the data
			// 	//	Cell: (props) => <div>{props.value}</div>,
			// 	Cell: (props) => (
			// 		<div>{numeral(props.value).format("$0,0.000")}</div>
			// 	),
			// },
			{
				Header: "changePercent",
				accessor: "changePercent", // accessor is the "key" in the data
				//	Cell: (props) => <div>{props.value}</div>,
				Cell: (props) => (
					<div className={props.value > 0 ? "green" : "red"}>
						{/* {props.value
							? numeral(props.value).format("(%0,0.000)")
							: "NA"} */}
						{numeral(props.value).format("(%0,0.000)")}
					</div>
				),
			},
		],
	},
	{
		Header: "Account",
		columns: [
			// {
			// 	Header: "initialValue",
			// 	accessor: "initialTotalValue", // accessor is the "key" in the data
			// 	//	Cell: (props) => <div>{props.value}</div>,
			// 	Cell: (props) => (
			// 		<div> {numeral(props.value).format("$0,0.00")}</div>
			// 	),
			// },
			// {
			// 	Header: "currentTotalValue",
			// 	accessor: "currentTotalValue", // accessor is the "key" in the data
			// 	//	Cell: (props) => <div>{props.value}</div>,
			// 	Cell: (props) => (
			// 		<div> {numeral(props.value).format("$0,0.00")}</div>
			// 	),
			// },
			// {
			// 	Header: "gainLoss",
			// 	accessor: "gainLoss", // accessor is the "key" in the data
			// 	Cell: (props) => (
			// 		<div className={props.value > 0 ? "green" : "red"}>
			// 			{" "}
			// 			{props.value
			// 				? numeral(props.value).format("($0,0.00)")
			// 				: "NA"}
			// 		</div>
			// 	),
			// },
			{
				Header: "gainLossPercent",
				accessor: "gainLossPercent", // accessor is the "key" in the data
				Cell: (props) => (
					<div className={props.value > 0 ? "green" : "red"}>
						{props.value
							? numeral(props.value).format("(%0,0.000)")
							: "NA"}
					</div>
				),
			},
			// {
			// 	Header: "Owners",
			// 	accessor: "ownerTotals", // accessor is the "key" in the data
			// 	Cell: (props) => (
			// 		<div className="">
			// 			{props.value.map((owner) => (
			// 				<Avatar uid={owner.uid} />
			// 			))}
			// 		</div>
			// 	),
			// },
		],
	},
];

// export const myMobileColumns = [
// 	{
// 		Header: "General",
// 		columns: [
// 			{
// 				Header: "Position",
// 				accessor: "name", // accessor is the "key" in the data
// 				Cell: (props) => <div className="color1">{props.value}</div>,
// 			},
// 		],
// 	},
// 	{
// 		Header: "Market Info",
// 		columns: [
// 			// {
// 			// 	Header: "latestPrice",
// 			// 	accessor: "latestPrice", // accessor is the "key" in the data
// 			// 	//	Cell: (props) => <div>{props.value}</div>,
// 			// 	Cell: (props) => (
// 			// 		<div>{numeral(props.value).format("$0,0.000")}</div>
// 			// 	),
// 			// },
// 			{
// 				Header: "Day",
// 				accessor: "changePercent", // accessor is the "key" in the data
// 				//	Cell: (props) => <div>{props.value}</div>,
// 				Cell: (props) => (
// 					<div className={props.value > 0 ? "green" : "red"}>
// 						{props.value
// 							? numeral(props.value).format("(%0,0.000)")
// 							: "NA"}
// 					</div>
// 				),
// 			},
// 		],
// 	},
// 	{
// 		Header: "Account",
// 		columns: [
// 			{
// 				Header: "All Time",
// 				accessor: "gainLossPercent", // accessor is the "key" in the data
// 				Cell: (props) => (
// 					<div className={props.value > 0 ? "green" : "red"}>
// 						{props.value
// 							? numeral(props.value).format("(%0,0.000)")
// 							: "NA"}
// 					</div>
// 				),
// 			},
// 			{
// 				Header: "Owners",
// 				accessor: "ownerTotals", // accessor is the "key" in the data
// 				Cell: (props) => (
// 					<div className="">
// 						{props.value.map((owner) => (
// 							<Avatar uid={owner.uid} key={owner.uid} />
// 						))}
// 					</div>
// 				),
// 			},
// 		],
// 	},
// ];

export const invalidColumns = [
	{
		Header: "Position",
		accessor: "name", // accessor is the "key" in the data
		Cell: (props) => <div>{props.value}</div>,
	},
	{
		Header: "Amount",
		accessor: "totalAmount", // accessor is the "key" in the data
		Cell: (props) => <div>{props.value}</div>,
	},
	{
		Header: "averageCost",
		accessor: "averageCost", // accessor is the "key" in the data
		Cell: (props) => <div>{props.value}</div>,
	},
];
