import { useHistory } from "react-router-dom";

export default function PageNotFound() {
	const history = useHistory();
	const goHome = () => {
		history.push("/");
	};
	return (
		<div>
			Here be dragons...<button onClick={goHome}>Go Home</button>
		</div>
	);
}
