import { useState } from "react";
import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import { useHistory } from "react-router-dom";

// styles and images

// import Temple from "../assets/temple.svg";
import { BiHomeAlt } from "react-icons/bi";
import { RiSettings3Line } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { GoX } from "react-icons/go";
import { ImExit } from "react-icons/im";
import Avatar from "./Avatar";
import Logo from "../assets/logo.png";

const Header = ({
	toggleSidebar,
	team,
	toggleSettings,
	userIsPremium,
	handlePurhcase,
}) => {
	const history = useHistory();
	//export default function Navbar({ truth }) {
	const { logout, isPending } = useLogout();
	const { user } = useAuthContext();
	const [showMenu, setShowMenu] = useState(false);
	let marketIsOpen = true;

	console.log("user", user);

	const handleButton = (location) => {
		setShowMenu(false);

		// location === "Dashboard" && history.push("/");
		location === "Settings" && toggleSettings();
		location === "Profile" && toggleSidebar();
		if (location === "Dashboard") {
			history.push("/");
			toggleSettings("off");
			toggleSidebar("off");
		}
		if (location === "Upgrade") {
			history.push("/upgrade");
			toggleSettings("off");
			toggleSidebar("off");
		}
	};

	isPending && console.log("logout pending", isPending);

	return (
		<header
			className={`header ${
				marketIsOpen ? "markets-open" : "markets-closed"
			}`}
		>
			<div className="content-container">
				<div className="header__content">
					<Link to="/" className="header__title">
						<img
							src={Logo}
							alt="Stocks with Friends"
							className="logo"
						/>
						<h1>{team}</h1>
					</Link>
					<div className="hamburger">
						<button onClick={() => setShowMenu(!showMenu)}>
							{showMenu ? (
								<GoX color="white" size="2.5em" />
							) : (
								<GiHamburgerMenu color="white" size="2.5em" />
							)}
						</button>
					</div>
					<div className={`menu ${showMenu && "show-mobile"}`}>
						<button
							className="header__title"
							onClick={() => handleButton("Dashboard")}
						>
							<BiHomeAlt className="menu-icon" />
							<span className="show-mobile">Dashboard</span>
						</button>
						<button
							onClick={() => handleButton("Settings")}
							className="menu--link settings--link"
						>
							<span className="show-mobile">Settings</span>
							<RiSettings3Line className="menu-icon" />
						</button>

						{/* {!user && (
							<>
								<Link to="/login">Login</Link>

								<Link to="/signup">Signup</Link>
							</>
						)} */}

						{user && (
							<button
								className="noBg"
								onClick={() => handleButton("Profile")}
							>
								<span className="show-mobile">Profile</span>

								<Avatar uid={user.uid} />
							</button>
						)}

						{!userIsPremium ? (
							isPending ? (
								<button className="btn" disabled>
									Loading...
								</button>
							) : (
								<button
									className="btn purchase"
									// onClick={() => handlePurhcase(user.uid)}
									onClick={() => handleButton("Upgrade")}
								>
									Upgrade
								</button>
							)
						) : (
							<h2>⭐️</h2>
						)}

						{/* {user && (
							<button className="menu--link" onClick={logout}>
								<ImExit className="menu-icon" />
								Logout
							</button>
						)} */}

						{/* <Sidebar companyName={auth.companyName} /> */}
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
