import { useParams } from "react-router-dom";
import { useDocument } from "../../hooks/useDocument";
import PositionSummary from "./PositionSummary";
import PositionComments from "./PositionComments";
// import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";

// styles
import "./Position.css";

export default function Position({ team, teamMates, positions }) {
	const history = useHistory();
	const { id } = useParams();
	//const { error, document } = useDocument("projects", id);
	const { error, document } = useDocument(`teams/${team}/positions`, id);

	//	const data = useLocation();
	//	console.log("location data! ", data);

	if (error) {
		return <div className="error">{error}</div>;
	}

	if (!document) {
		return <div className="loading">Loading...</div>;
	}
	console.log("detail doc", document);

	return (
		<div className="content-wrapper">
			<div className="project-details">
				<PositionSummary
					position={document}
					teamMates={teamMates}
					positions={positions}
				/>
				<PositionComments
					position={document}
					teamMates={teamMates}
					team={team}
				/>
				{/* <Link to="/"> to Dashboard</Link> */}
				<button onClick={() => history.goBack()}>Go Back</button>
			</div>
		</div>
	);
}
