import { useState } from "react";
import { useSignup } from "../../hooks/useSignup";
import { useCollection } from "../../hooks/useCollection";
//import TeamChecker from "../../components/TeamChecker";
import { projectFirestore } from "../../firebase/config";
import { Link } from "react-router-dom";

// styles
import "./Signup.css";

export default function Signup() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [displayName, setDisplayName] = useState("");
	const [team, setTeam] = useState("");
	const [thumbnail, setThumbnail] = useState(null);
	const [thumbnailError, setThumbnailError] = useState(null);
	const [teamError, setTeamError] = useState(null);
	const { signup, isPending, error } = useSignup();
	const [teamDropdown, setTeamDropDown] = useState(false);
	const [createTeam, setCreateTeam] = useState(false);
	const [approvedTeams, setApprovedTeams] = useState({});

	const { documents } = useCollection("approvedUsers");
	//const { documents } = useCollection(`teams/mdmj2/users/`);

	// documents && console.log("documents", documents);

	// error && setTeamDropDown(false);
	// error && setCreateTeam(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		// query here, if team found, add it, if not popup team input.
		let myApprovedTeams = documents.filter((item) => item.email === email);
		console.log("data", myApprovedTeams, "length", myApprovedTeams.length);
		//console.log("First team", myApprovedTeams[0].team);
		if (myApprovedTeams.length === 1) {
			console.log("found approved team ");
			// setTeam();
			signup(
				email,
				password,
				displayName,
				thumbnail,
				myApprovedTeams[0].team
			);
		} else if (myApprovedTeams.length > 1) {
			console.log("found multiple approved teams ");
			setTeamDropDown(true);
			setApprovedTeams(myApprovedTeams);
		} else {
			console.log("Found no approved eams", myApprovedTeams.length);

			setTeamDropDown(true);
			setCreateTeam(true);
		}
	};

	const handleTeamSelect = (e) => {
		console.log("handle team select");
		e.preventDefault();
		setTeamError(null);
		console.log(email, password, displayName, thumbnail, team);

		console.log("team selected! ", team);

		let ref = projectFirestore.collection(`teams/${team}/users/`);

		ref.onSnapshot(
			(snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					console.log("team docs!", doc);
					results.push({ ...doc.data(), id: doc.id });
				});
				console.log("results", results);
				// update state
				if (results.length > 0) {
					console.log(
						"RESULTS GREATER THAN 0 - sorry, team name is taken "
					);
					setTeamError("Sorry, Team name is already in use");
				} else {
					setTeamError(null);
					console.log("lets sign up!");
					signup(email, password, displayName, thumbnail, team);
				}
			},
			(error) => {
				console.log(error);
				//setError("could not fetch the data");
			}
		);

		//
	};

	const handleTeam = (e) => {
		setTeamError(null);
		if (e.currentTarget.value.includes("/")) {
			setTeamError("Please don't use / in team names");
			return;
		}
		if (e.currentTarget.value.includes(".")) {
			setTeamError("Please don't use '.' in team names");
			return;
		}
		setTeam(e.target.value);
	};

	const handleFileChange = (e) => {
		setThumbnail(null);
		let selected = e.target.files[0];
		console.log(selected);

		if (!selected) {
			setThumbnailError("Please select a file");
			return;
		}
		if (!selected.type.includes("image")) {
			setThumbnailError("Selected file must be an image");
			return;
		}
		if (selected.size > 400000) {
			setThumbnailError("Image file size must be less than 400kb");
			return;
		}
		setThumbnailError(null);
		setThumbnail(selected);
		console.log("thumbnail updated");
	};

	return (
		<>
			{!teamDropdown && (
				<form className="auth-form" onSubmit={handleSubmit}>
					<h2>Sign up</h2>
					<label>
						<span>email:</span>
						<input
							required
							type="email"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
						/>
					</label>
					<label>
						<span>password:</span>
						<input
							required
							type="password"
							onChange={(e) => setPassword(e.target.value)}
							value={password}
							minLength="6"
						/>
					</label>

					<label>
						<span>display name:</span>
						<input
							required
							type="text"
							onChange={(e) => setDisplayName(e.target.value)}
							value={displayName}
						/>
					</label>
					<label>
						<span>profile thumbnail:</span>
						<input
							type="file"
							required
							onChange={handleFileChange}
						/>
						{thumbnailError && (
							<div className="error">{thumbnailError}</div>
						)}
					</label>
					{!isPending && <button className="btn">Sign Up</button>}
					{isPending && (
						<button disabled className="btn">
							Loading
						</button>
					)}
					{error && <div className="error">{error}</div>}
					<Link className="message flex" to="/login">
						Already have an account? Login
					</Link>
				</form>
			)}

			{teamDropdown && (
				<form className="auth-form" onSubmit={handleTeamSelect}>
					<label>
						{!createTeam && (
							<>
								<span>Select your team:</span>
								<select onChange={(e) => handleTeam(e)}>
									{approvedTeams &&
										approvedTeams.map((team) => (
											<option
												key={team.team}
												value={team.team}
											>
												{team.team}
											</option>
										))}
								</select>
							</>
						)}
						{createTeam && (
							<>
								<span>Create your team name:</span>
								<input
									type="text"
									onChange={(e) => handleTeam(e)}
									value={team}
								/>
							</>
						)}
					</label>

					{teamError && !isPending && (
						<div className="error">{teamError}</div>
					)}
					{!isPending && <button className="btn">Submit</button>}

					{isPending && (
						<button disabled className="btn">
							Loading
						</button>
					)}
					<Link className="message flex" to="/login">
						Already have an account? Login
					</Link>
				</form>
			)}
		</>
	);
}
