import { useEffect, useState } from "react";
// import Select from "react-select";

// import { timestamp } from "../../firebase/config";

// import { useFirestore } from "../../hooks/useFirestore";
import { useCollection } from "../../hooks/useCollection";
import { usePosition } from "../../hooks/usePosition";
import { useHistory } from "react-router-dom";
// For Date Picker
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

// Mui
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// Icons
import CircularProgress from "@mui/material/CircularProgress";

// const categories = [
// 	{ value: "development", label: "Development" },
// 	{ value: "design", label: "Design" },
// 	{ value: "sales", label: "Sales" },
// 	{ value: "marketing", label: "Marketing" },
// ];

// Creating Position
export default function Create({ user, team }) {
	const history = useHistory();
	const { documents } = useCollection(`teams/${team}/positions/`);
	const { addPosition, updatePosition, response } = usePosition(team);
	// form field values
	const [name, setName] = useState("");
	const [amount, setAmount] = useState("");
	const [price, setPrice] = useState("");
	const [createdAt, setCreatedAt] = useState(moment());
	const [formError, setFormError] = useState(null);
	const [formMessage, setformMessage] = useState(null);
	const [isPending, setIsPending] = useState(false);
	const [bulkAdd, setBulkAdd] = useState(false);

	const [calendarFocused, setCalendarFocused] = useState(false);

	const onDateChange = (createdAt) => {
		if (createdAt) {
			console.log("setting new date", createdAt);
			setCreatedAt(createdAt);
		}
	};
	const onFocusChange = ({ focused }) => {
		setCalendarFocused(focused);
	};

	// AutoComplete Stuff
	const [searchTerm, setSearchTerm] = useState("");
	// API search results
	const [results, setResults] = useState([]);
	const [value, setValue] = useState(results[0]);
	// Searching status (whether there is pending API request)
	const [isSearching, setIsSearching] = useState(false);
	// const [errorMessage, setErrorMessage] = useState();
	// Debounce search term so that it only gives us latest value ...
	// ... if searchTerm has not been updated within last 500ms.
	// The goal is to only have the API call fire when user stops typing ...
	// ... so that we aren't hitting our API rapidly.
	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	//	console.log("is searching", isSearching);

	//	console.log("name", name);
	// Effect for API call
	useEffect(
		() => {
			console.log("running use effect");
			if (debouncedSearchTerm) {
				//	console.log("SEARCHING");
				setIsSearching(true);
				searchCharacters(debouncedSearchTerm).then((data) => {
					//console.log("add results effect", data);
					setIsSearching(false);
					setformMessage(null);
				});
			} else {
				setResults([]);
				setIsSearching(false);
			}
		},
		[debouncedSearchTerm] // Only call effect if debounced search term changes
	);

	//   function handleOptionSelect(e) {
	//     console.log("option click", e.target);
	//   }

	// API search function
	function searchCharacters(search) {
		//		console.log("SEARCH CHARACTERS");
		return (
			fetch(
				`${process.env.REACT_APP_CLOUD_IEXAPIS}/stable/search/${search}/?token=${process.env.REACT_APP_CLOUD_API_KEY}`,
				{
					method: "GET",
				}
			)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					console.log("SETTING results data", data);
					setResults(data);
					// undefined here
				})
				//  .then((r) => r.data.results)
				.catch((error) => {
					console.error("got an error", error);
					return [];
				})
		);
	}
	// Hook
	function useDebounce(value, delay) {
		//console.log("running useDeboucne");
		// State and setters for debounced value
		const [debouncedValue, setDebouncedValue] = useState(value);
		//	console.log("dobounce value:", debouncedValue);
		useEffect(
			() => {
				//	console.log("using effect in deboucne");
				// Update debounced value after delay
				const handler = setTimeout(() => {
					setDebouncedValue(value);
				}, delay);
				// Cancel the timeout if value changes (also on delay change or unmount)
				// This is how we prevent debounced value from updating if value is changed ...
				// .. within the delay period. Timeout gets cleared and restarted.
				return () => {
					clearTimeout(handler);
				};
			},
			[value, delay] // Only re-call effect if value or delay changes
		);
		return debouncedValue;
	}

	// end auto complete stuff

	const handleSubmit = async (e) => {
		e.preventDefault();
		setFormError(null);
		setIsPending(true);

		console.log("submited!", value);

		//	console.log("check these docs", documents, "against", value);

		const owner = {
			// user: user.displayName,
			// createdBy
			// id: user.uid,
			price,
			amount,
			uid: user.uid,
			createdDate: createdAt.toDate(),
			//	createdDate: c,
		};

		const position = {
			name: value,
			comments: [],
			owners: [owner],
		};

		//	console.log(position);

		if (value) {
			let existing = documents.find((x) => x.name === value);
			if (existing) {
				console.log(name, "already exists!", existing.id);

				await updatePosition(existing.id, {
					owners: [...existing.owners, owner],
				});
			} else {
				console.log(name, "not found, let's add!", position);
				await addPosition(position);
			}

			// save it to firebase

			console.log("add position response", response);
			if (!response.error) {
				setIsPending(false);
				setValue(null);
				setformMessage(`${value} added successfully!`);
				setName("");
				setAmount("");
				setPrice("");
				console.log("clearning results", results);
				setResults([]);
				console.log(
					"results after clear",
					results,
					"value,",
					value,
					"search term",
					searchTerm
				);

				console.log(
					"results after value",
					results,
					"value,",
					value,
					"search term",
					searchTerm
				);
				setSearchTerm(null);

				!bulkAdd && history.push("/");
			}
		} else {
			setFormError("Please select a symbol");
			setIsPending(false);
		}
	};

	return (
		<div className="content-wrapper">
			<div className="create-form">
				<h1 className="page-title">Add a new Position</h1>
				<form onSubmit={handleSubmit}>
					<label className="flex relative">
						{/* <span>Symbol:</span> */}
						{/* <input
						required
						type="text"
						onChange={(e) => setName(e.target.value.toUpperCase())}
						value={name}
					/> */}

						<Autocomplete
							required
							disablePortal
							clearOnBlur
							options={results}
							// sx={{ width: 300 }}
							onChange={(event, newValue) => {
								//	console.log("new value!", newValue);
								newValue
									? setValue(newValue.symbol)
									: setValue(null);
							}}
							getOptionLabel={(option) => option.symbol}
							renderOption={(props, option) => {
								return (
									<li {...props}>
										{/* class = renderOption, but this disables highlighting */}
										<div className="autocomplete__symbol">
											{`${option.symbol}`}{" "}
										</div>
										<span className="auto_security">
											{option.securityName}
										</span>
										<span className="auto_exchange">
											{option.exchangeName}
										</span>
									</li>
								);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Symbol"
									onChange={(e) =>
										setSearchTerm(e.target.value)
									}
								/>
							)}
						/>
						{isSearching && <CircularProgress />}
					</label>

					<label>
						<span>Amount:</span>
						<input
							required
							type="number"
							onChange={(e) => setAmount(e.target.value)}
							value={amount}
						/>
					</label>
					<label>
						<span>Price per Share:</span>
						<input
							required
							type="number"
							onChange={(e) => setPrice(e.target.value)}
							value={price}
						/>
					</label>

					<SingleDatePicker
						date={createdAt}
						onDateChange={onDateChange}
						focused={calendarFocused}
						onFocusChange={onFocusChange}
						numberOfMonths={1}
						isOutsideRange={() => false}
					/>

					{/* <label>
          <span>Assign to:</span>
          <Select
            options={users}
            onChange={(option) => setAssignedUsers(option)}
            isMulti
          />
        </label> */}
					{isPending ? (
						<button className="btn">Loading</button>
					) : (
						<button className="btn">Add Position</button>
					)}

					<button
						type="button"
						className="btn"
						onClick={() => history.push("/")}
					>
						Cancel
					</button>
					<label className="flex checkbox">
						<span>Bulk Add:</span>
						<input
							type="checkbox"
							value={bulkAdd}
							onChange={() => setBulkAdd(!bulkAdd)}
						/>
					</label>
					{formError && <p className="error">{formError}</p>}
					{formMessage && <p className="success">{formMessage}</p>}
				</form>
			</div>
		</div>
	);
}
