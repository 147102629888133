import { useCollection } from "./../hooks/useCollection";

//components

export default function ApprovedUsers({ team }) {
	const { documents } = useCollection("approvedUsers");

	return (
		<div className="user-list center">
			<h4>Approved Users</h4>
			{documents &&
				documents
					.filter((item) => item.team === team)
					.map((user) => (
						<div key={user.createdAt} className="user-list-item">
							<span className="small">{user.email}</span>
						</div>
					))}
		</div>
	);
}
