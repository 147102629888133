//import { usePostionCollection } from "../../hooks/usePostionCollection";
import { useState } from "react";

// Components
import ProjectList from "../../components/ProjectList";
import PositionsTable from "../../components/PositionsTable";
import TeamSummary from "./TeamSummary";
import MarketInfo from "./MarketInfo";
// import ProjectFilter from "./ProjectFilter";

// styles
import "./Dashboard.css";
import TeamDisplay from "./TeamDisplay";

// icons

import { MdTableRows } from "react-icons/md";
import { BsGrid3X2GapFill } from "react-icons/bs";
import Dow from "./Dow";
//import { FaPlus } from "react-icons/fa";

export default function Dashboard({ team, teamMates, documents }) {
	//const { documents, error } = usePostionCollection(team);

	// const [filter, setFilter] = useState("all");
	// const [serverData, setServerData] = useState(null);

	// test serve connection
	// useEffect(() => {
	// 	// fetch("/api")
	// 	// 	.then((res) => res.json())
	// 	// 	.then((data) => setServerData(data.message));
	// }, []);

	// filters (not being used currently)
	// const projects = documents
	// 	? documents.filter((document) => {
	// 			switch (filter) {
	// 				case "all":
	// 					return true;
	// 				case "mine":
	// 					let assignedToMe = false;
	// 					document.owners.forEach((u) => {
	// 						if (u.uid === user.uid) {
	// 							assignedToMe = true;
	// 						}
	// 					});
	// 					return assignedToMe;
	// 				// case "development":
	// 				// case "design":
	// 				// case "sales":
	// 				// case "marketing":
	// 				//   console.log(document.category, filter);
	// 				//   return document.category === filter;
	// 				default:
	// 					return true;
	// 			}
	// 	  })
	// 	: null;

	//documents && console.log("documents right before render", documents);

	// console.log("team mate state", teamMates);

	//	console.log("dashboard team", team, "and my team mates", teamMates);

	const [tableView, setTableView] = useState(true);

	const handleTableView = () => {
		console.log("clicked");
		setTableView(true);
	};

	const handleCardsView = () => {
		console.log("clicked2");
		setTableView(false);
	};

	return (
		<div className="content-wrapper">
			{/* Server Data: let's git {!serverData ? "loading" : serverData} */}
			{/* {error && <p className="error">{error}</p>} */}
			{/* {documents && <ProjectFilter changeFilter={changeFilter} />} */}
			{/* {projects && <ProjectList projects={projects} />} */}
			{documents && (
				<>
					<div className="card-layout">
						<div className="users">
							<TeamSummary
								positions={documents}
								teamMates={teamMates}
							/>
							<TeamDisplay
								positions={documents}
								teamMates={teamMates}
							/>
						</div>
						<MarketInfo
							positions={documents}
							teamMates={teamMates}
						/>
						<Dow />
					</div>
					<div className="dash-toolbar">
						<button
							className={`icon-button ${tableView && "active"}`}
							onClick={handleTableView}
						>
							<MdTableRows />
						</button>
						<button
							className={`icon-button top-adjust ${
								!tableView && "active"
							}`}
							onClick={handleCardsView}
						>
							<BsGrid3X2GapFill />
						</button>
					</div>
					{tableView && (
						<PositionsTable
							positions={documents}
							teamMates={teamMates}
						/>
					)}
					{!tableView && (
						<ProjectList
							positions={documents}
							teamMates={teamMates}
						/>
					)}
				</>
			)}
		</div>
	);
}
