import numeral from "numeral";
import Avatar from "../../components/Avatar";
import Number from "../../components/Number";
import moment from "moment";

export default function MarketInfo({ positions, teamMates }) {
	console.log("gainer positions", positions);
	return (
		<div className="marketInfo">
			<div className="marketInner">
				<section>
					<h3>Today's Top Gainers</h3>

					{positions
						.sort((b, a) =>
							a.changePercent > b.changePercent ? 1 : -1
						)
						.slice(0, 5)
						.filter((item) => item.changePercent > 0)
						.map((item) => (
							<div key={item.name} className="market-item">
								<div className="market-name">{item.name}: </div>
								<div className="green">
									{numeral(item.changePercent).format(
										"%0,0.00"
									)}{" "}
								</div>
								<div className="small update">
									{" "}
									{moment(item.latestUpdate).fromNow()}
								</div>
								<div className="portrait-icon-wrapper">
									{item.ownerTotals.map((owner) => (
										<div key={owner.uid}>
											<div className="tooltip">
												<Avatar uid={owner.uid} />
												<span className="tooltiptext">
													Total{" "}
													{owner.gainLossPercent > 0
														? "Gain"
														: "Loss"}{" "}
													<Number
														value={
															owner.gainLossPercent
														}
														percent={true}
													/>
													<div className="arrow-down"></div>
												</span>
											</div>
										</div>
									))}
								</div>
							</div>
						))}
				</section>
				<section>
					<h3>Today's Biggest Losers</h3>
					{/* create arrray with only unique values for description. sort that. 
	for your split array, map in the larger array and find users with that position. 
*/}

					{positions
						.sort((a, b) =>
							a.changePercent > b.changePercent ? 1 : -1
						)
						.slice(0, 5)
						.filter((item) => item.changePercent < 0)
						.map((item) => (
							<div key={item.name} className="market-item">
								<div className="market-name">{item.name}: </div>
								<div className="red">
									{numeral(item.changePercent).format(
										"%0,0.00"
									)}
								</div>
								<div className="small update">
									{" "}
									{moment(item.latestUpdate).fromNow()}
								</div>
								<div className="portrait-icon-wrapper">
									{item.ownerTotals.map((owner) => (
										<div key={owner.uid}>
											<div className="tooltip">
												<Avatar uid={owner.uid} />
												<span className="tooltiptext">
													Total{" "}
													{owner.gainLossPercent > 0
														? "Gain"
														: "Loss"}{" "}
													<Number
														value={
															owner.gainLossPercent
														}
														percent={true}
													/>
													<div className="arrow-down"></div>
												</span>
											</div>
										</div>
									))}
								</div>
							</div>
						))}
				</section>
			</div>
		</div>
	);
}
