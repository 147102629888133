import { useState, useEffect } from "react";
import {
	projectAuth,
	projectStorage,
	projectFirestore,
} from "../firebase/config";
import { useAuthContext } from "./useAuthContext";

export const useUpdateProfile = () => {
	const [isCancelled, setIsCancelled] = useState(false);
	const [error, setError] = useState(null);
	const [isPending, setIsPending] = useState(false);
	const { dispatch } = useAuthContext();
	const [success, setSuccess] = useState(false);

	const update = async (
		team,
		user,
		email,
		password,
		displayName,
		thumbnail
	) => {
		setError(null);
		setIsPending(true);
		try {
			//	console.log("hook pass and thumb", password, thumbnail);

			user.updateEmail(email)
				.then(() => {
					// Update successful
					console.log("user email updated successfully");
					// ...
				})
				.catch((error) => {
					// An error occurred
					// ...
					throw new Error("Could not update user email");
				});

			// update Password
			password &&
				user
					.updatePassword(password)
					.then(() => {
						// Update successful
						console.log("user password updated successfully");
						// ...
					})
					.catch((error) => {
						// An error occurred
						// ...
						throw new Error("Could not update user password");
					});

			// signup user
			// const res = await user.updateEmail(
			// 	email,

			// );
			// console.log("user response:", res.user);

			// if (!res) {
			// 	throw new Error("Could not complete signup");
			// }

			// signup user
			// const res = await projectAuth.createUserWithEmailAndPassword(
			// 	email,
			// 	password
			// );
			// console.log("user response:", res.user);

			// if (!res) {
			// 	throw new Error("Could not complete signup");
			// }

			// upload user thumbnail
			if (thumbnail) {
				let fileUrl = user.photoURL;

				// Create a reference to the file to delete
				let fileRef = projectStorage.refFromURL(fileUrl);

				// Delete the file
				await fileRef
					.delete()
					.then(() => {
						console.log("old thumb deleted");
						// File deleted successfully
					})
					.catch((error) => {
						// Uh-oh, an error occurred!
						throw new Error("Could not delete old photo");
					});

				const uploadPath = `thumbnails/${user.uid}/${thumbnail.name}`;
				const img = await projectStorage.ref(uploadPath).put(thumbnail);
				const imgUrl = await img.ref.getDownloadURL();
				// add display name to user
				await user.updateProfile({ displayName, photoURL: imgUrl });

				await projectFirestore
					.collection("teams")
					.doc(team)
					.collection("users")
					.doc(user.uid)
					.update({
						online: true,
						displayName,
						photoURL: imgUrl,
						email,
					});
			} else {
				await user.updateProfile({ displayName });
			}

			// update the user document
			await projectFirestore
				.collection("users")
				.doc(user.uid)
				.update({ email });

			// update the team document
			await projectFirestore
				.collection("teams")
				.doc(team)
				.collection("users")
				.doc(user.uid)
				.update({
					online: true,
					displayName,
					// photoURL: imgUrl,
					email,
				});

			// dispatch login action
			//	dispatch({ type: "LOGIN", payload: res.user });

			if (!isCancelled) {
				setIsPending(false);
				setError(null);
				setSuccess(true);
			}
		} catch (err) {
			if (!isCancelled) {
				console.log(err.message);
				setError(err.message);
				setIsPending(false);
			}
		}
	};

	useEffect(() => {
		return () => setIsCancelled(true);
	}, []);

	return { error, isPending, update, success };
};
