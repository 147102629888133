import { firebase } from "../firebase/config";
import getStripe from "./initializeStripe";

export async function createCheckoutSession(uid) {
  const firestore = firebase.firestore();

  //create a new checkout session in the subcollection inside this users document
  const checkoutSessionRef = await firestore
    .collection("users")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      price: "price_1KZBJqHtye1HhTqMIzTKD1YP",
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

  // wait for the checkoutSession to get attached by the extension
  checkoutSessionRef.onSnapshot(async (snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      // we have a session, let's redirect to checkout
      // init stripe
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    }
  });
}
