import Avatar from "../../components/Avatar";
// import { useFirestore } from "../../hooks/useFirestore";
import { useAuthContext } from "../../hooks/useAuthContext";
// import { usePositionsPerUser } from "../../hooks/usePositionsPerUser";
import { useHistory } from "react-router-dom";
import Number from "../../components/Number";

import numeral from "numeral";

export default function PositionSummary({ position, teamMates, positions }) {
	// const { deleteDocument } = useFirestore("projects");

	// const { documents, error } = usePositionsPerUser(teamMates, positions);

	const { user } = useAuthContext();
	const history = useHistory();

	const handleEdit = (user, position) => {
		history.push({
			pathname: `/edit/${position.id}`,
			id: position.id,
			transaction: { detail: user },
		});
	};

	console.log("summary positions", positions);
	console.log("summary position to find", position);

	let positionData = positions.find((x) => x.name === position.name);

	console.log("summary position data", positionData);

	// const handleClick = (e) => {
	// 	// deleteDocument(position.id);
	// 	// history.push(`/`);
	// 	history.push(`/edit/${position.id}`);
	// };
	return (
		<div>
			<div className="project-summary">
				<h1>{position.name}</h1>

				<div>
					{positionData.totalAmount} shares at average price of{" "}
					{numeral(positionData.averageCost).format("$0,0.00")}. Total{" "}
					{positionData.gainLoss >= 0 ? " Gain" : " Loss"}:{" "}
					<Number value={positionData.gainLoss} percent="false" />{" "}
					<Number
						value={positionData.gainLossPercent}
						percent={true}
					/>
				</div>

				<hr />

				<h3>Totals</h3>

				{positionData.ownerTotals.map((user, index) => {
					return (
						<div className="item" key={index}>
							<Avatar uid={user.uid} name={true} />
							{" owns "}
							{user.totaledAmount} shares average at{" "}
							{numeral(user.averageCost).format("$0,0.00")}.{" "}
							{user.gainLossPercent > 0 ? (
								<span className="spacer">Gain: </span>
							) : (
								<span className="spacer">Loss: </span>
							)}
							<div
								className={
									user.gainLossPercent > 0 ? "green" : "red"
								}
							>
								{" "}
								{numeral(user.gainLossPercent).format(
									"%0,0.000"
								)}
							</div>
						</div>
					);
				})}

				<h3>Activity</h3>
				{position.owners &&
					position.owners.map((owner, index) => {
						return (
							<div className="item" key={index}>
								<Avatar uid={owner.uid} name="true" />
								{" bought "}
								{owner.amount} shares at ${owner.price} {" on "}
								{owner.createdDate.toDate().toDateString()}
								{owner.uid === user.uid && (
									<button
										onClick={() =>
											handleEdit(owner, position)
										}
									>
										Edit
									</button>
								)}
							</div>
						);
					})}
			</div>
		</div>
	);
}
