import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
// import numeral from "numeral";

// Components
import Table from "../../components/Tables/Table";
// import Avatar from "../../components/Avatar";
import TeamSummary from "../dashboard/TeamSummary";
import { usePositionsPerUser } from "../../hooks/usePositionsPerUser";

// Data
import {
	ProfileColumns,
	ProfileMobileColumns,
} from "../../components/Tables/Columns.js";

// Icons
import { FaPlus } from "react-icons/fa";
import Avatar from "../../components/Avatar";

export default function Profile({ positions, teamMates }) {
	const { documents } = usePositionsPerUser(teamMates, positions);
	const { id } = useParams();

	const columns = useMemo(() => ProfileColumns, []);
	const MobileColumns = useMemo(() => ProfileMobileColumns, []);

	console.log("profile documents", documents);
	console.log("profile teammates", teamMates);

	//	documents && console.log("profile docs1", documents);

	const ourUser =
		documents && documents.filter((user) => user.displayName === id)[0];

	console.log("profile user", ourUser);

	return (
		<div className="content-wrapper profile__wrapper">
			{/* {documents && documents.filter((item) => item.displayName === id).map((position)) */}
			<div className="col__wrapper">
				<div className="col">
					{ourUser && (
						<div className="card-layout__box profile__box">
							<Avatar uid={ourUser.id} type="large" />
							<h2>{ourUser.displayName}</h2>
							{/* <div>{ourUser.email}</div> */}
							<div>{ourUser.online}</div>

							{/* <img
								width="50px"
								src={ourUser.photoURL}
								alt={ourUser.displayName}
							/> */}
						</div>
					)}
				</div>
				<div className="col">
					{ourUser && (
						<TeamSummary
							positions={ourUser.positions}
							teamMates={teamMates}
						/>
					)}
				</div>
			</div>

			{ourUser && (
				<div className="show-mobile">
					<Table
						data={ourUser.positions}
						columns={MobileColumns}
						teamMates={teamMates}
						clickToView={true}
						hiddenColumns={[]}
					/>
				</div>
			)}

			{ourUser && (
				<div className="hide-mobile">
					<Table
						data={ourUser.positions}
						columns={columns}
						teamMates={teamMates}
						clickToView={true}
						hiddenColumns={[]}
					/>
				</div>
			)}
			<div className="page-header__actions">
				<Link className="button add_position" to="/create">
					<FaPlus />
				</Link>
			</div>
		</div>
	);
}
