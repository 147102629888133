import { useRef, useEffect } from "react";

export function useInterval(callback, delay) {
	const savedCallback = useRef();
	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	});
	// Set up the interval.
	useEffect(() => {
		function tick() {
			if (typeof savedCallback?.current === "function") {
				savedCallback.current();
			}
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

export function usePrevious(value) {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
}
