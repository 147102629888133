import { NavLink } from "react-router-dom";
// import Avatar from "./Avatar";
import { useAuthContext } from "../hooks/useAuthContext";

import OnlineUsers from "./OnlineUsers";
import AddUsers from "./AddUsers";
import ApprovedUsers from "./approvedUsers";
import { useLogout } from "../hooks/useLogout";

// styles & images

import DashboardIcon from "../assets/dashboard_icon.svg";
import AddIcon from "../assets/add_icon.svg";

export default function Settings({ team, settingsAreVisible, teamMates }) {
	const { user } = useAuthContext();
	const { logout, isPending } = useLogout();

	return (
		<div
			className={`sidebar-right settings ${
				settingsAreVisible ? "visible" : "hidden"
			}`}
		>
			{/* <div className="control">
				<h3>Settings</h3>
			</div> */}
			{user && teamMates && (
				<OnlineUsers user={user} team={team} teamMates={teamMates} />
			)}
			<div className="control">
				{" "}
				{user && <AddUsers user={user} team={team} />}
				{user && <ApprovedUsers team={team} />}
				<div className="user-list control">
					<button className="btn" onClick={logout}>
						Logout
					</button>
				</div>
			</div>
		</div>
	);
}
