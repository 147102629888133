import { useReducer, useEffect, useState } from "react";

// we use this object everytime we want to interact with firestore
import { projectFirestore, timestamp } from "../firebase/config";

let initalState = {
	document: null,
	isPending: false,
	error: null,
	success: null,
};

const firestoreReducer = (state, action) => {
	switch (action.type) {
		case "IS_PENDING":
			return {
				success: false,
				isPending: true,
				error: null,
				document: null,
			};

		case "ADDED_DOCUMENT":
			return {
				success: true,
				isPending: false,
				error: null,
				document: action.payload,
			};
		case "DELETED_DOCUMENT":
			return {
				isPending: false,
				document: null,
				success: true,
				error: null,
			};
		case "UPDATED_DOCUMENT":
			return {
				isPending: false,
				document: action.payload,
				success: true,
				error: null,
			};
		case "ERROR":
			return {
				success: false,
				isPending: false,
				error: action.payload,
				document: null,
			};
		default:
			return state;
	}
};

export const useFirestore = (collection, id, subcollection) => {
	//console.log("use firestore props", collection, id, subcollection);
	const [response, dispatch] = useReducer(firestoreReducer, initalState);
	const [isCanceled, setIsCanceled] = useState(false);
	// console.log("useFirestore collection", collection, uid, subcollection);

	//collection ref - original. Now we've divided it to allow for sub collections.
	// const ref = projectFirestore.collection(collection);
	let ref;

	if (id && subcollection) {
		// console.log("inside the if!");
		ref = projectFirestore.collection(
			`${collection}/${id}/${subcollection}`
		);
		ref = projectFirestore
			.collection(collection)
			.doc(id)
			.collection(subcollection);
	} else {
		//console.log("the else");
		ref = projectFirestore.collection(collection);
	}
	// console.log("our ref!!", ref);

	// only dispatch if not canceled
	const dispatchIfNotCanceled = (action) => {
		if (!isCanceled) {
			dispatch(action);
		}
	};

	// add document
	const addDocument = async (doc) => {
		dispatch({ type: "IS_PENDING" });

		try {
			const createdAt = timestamp.fromDate(new Date());
			const addedDocument = await ref.add({ ...doc, createdAt });
			dispatchIfNotCanceled({
				type: "ADDED_DOCUMENT",
				payload: addedDocument,
			});
		} catch (err) {
			dispatchIfNotCanceled({ type: "ERROR", payload: err.message });
		}
	};

	// delete document
	const deleteDocument = async (id) => {
		dispatch({ type: "IS_PENDING" });
		try {
			await ref.doc(id).delete();
			dispatchIfNotCanceled({
				type: "DELETED_DOCUMENT",
			});
		} catch (err) {
			dispatchIfNotCanceled({
				type: "ERROR",
				payload: "could not delete",
			});
		}
	};

	//update documents
	const updateDocument = async (id, updates) => {
		dispatch({ type: "IS_PENDING" });
		console.log("running update doucment!,", id, "updates:", updates);
		try {
			const updatedDocument = await ref.doc(id).update(updates);
			dispatchIfNotCanceled({
				type: "UPDATED_DOCUMENT",
				payload: updatedDocument,
			});
			return updatedDocument;
		} catch (err) {
			dispatchIfNotCanceled({ type: "ERROR", payload: err.message });
			return null;
		}
	};

	useEffect(() => {
		return () => setIsCanceled(true);
	}, []);

	return { addDocument, deleteDocument, updateDocument, response };
};
