import { useEffect, useState } from "react";

// this takes owner totals and reformats to an object that has a loop of users with their positions.
export const usePositionsPerUser = (teamMates, positions) => {
	const [documents, setDocuments] = useState(null);
	const [error, setError] = useState(null);
	useEffect(() => {
		const teamMatesState = teamMates.map((mate) => {
			let ownedDocuments = [];
			if (Array.isArray(positions)) {
				positions.forEach((doc) => {
					// console.log("for each doc, looking at owner totals,", doc);
					let docFound = doc.ownerTotals.find(
						({ uid: docUid }) => docUid === mate.id
					);
					if (docFound) {
						// console.log("doc found", docFound);
						// console.log("and the doc", doc);
						ownedDocuments.push({
							name: doc.name,
							totalAmount: docFound.totaledAmount,
							initialTotalValue: docFound.initalTotaledValue,
							currentTotalValue: docFound.currentTotalValue,
							gainLoss: docFound.gainLoss,
							gainLossPercent: docFound.gainLossPercent,
							averageCost: docFound.averageCost,
							todayGainLoss: docFound.todayGainLoss,
							change: docFound.change,
							changePercent: docFound.changePercent,
							latestPrice: docFound.latestPrice,
							totalTodayChange: docFound.totalTodayChange,
							id: doc.id,
							//id: docFound.id,
						});
					}
				});
				return {
					id: mate.id,
					displayName: mate.displayName,
					email: mate.email,
					photoURL: mate.photoURL,
					team: mate.team,
					online: mate.online,
					positions: ownedDocuments,
				};
			} else {
				setError("not an arrray");
				console.log("not an array!");
				return null;
			}
		});

		setDocuments(teamMatesState);
	}, [positions, teamMates]);

	return { documents, error };
};
