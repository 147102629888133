import { useEffect, useState } from "react";
import { timestamp } from "../../firebase/config";
import { useFirestore } from "../../hooks/useFirestore";
import { useDocument } from "../../hooks/useDocument";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import moment from "moment";
// import { RiContactsBookLine } from "react-icons/ri";

// styles
//import "./Create.css";

// might use these for different position types.
// const categories = [
// 	{ value: "development", label: "Development" },
// 	{ value: "design", label: "Design" },
// 	{ value: "sales", label: "Sales" },
// 	{ value: "marketing", label: "Marketing" },
// ];

export default function EditPosition({ team }) {
	// dom stuff
	const history = useHistory();
	const location = useLocation();

	console.log("location!", location);

	//state
	const [name, setName] = useState("");
	const [amount, setAmount] = useState("");
	const [price, setPrice] = useState("");
	const [isPending, setIsPending] = useState(false);
	const [formError, setFormError] = useState(null);

	//dates
	//	{position.createdAt.toDate().toDateString()}
	const [createdAt, setCreatedAt] = useState(moment());
	const [calendarFocused, setCalendarFocused] = useState(false);

	const { updateDocument, deleteDocument, response } = useFirestore(
		"teams",
		team,
		"positions"
	);

	const onDateChange = (createdAt) => {
		if (createdAt) {
			console.log("setting new date", createdAt);
			setCreatedAt(createdAt);
		}
	};
	const onFocusChange = ({ focused }) => {
		setCalendarFocused(focused);
	};

	useEffect(() => {
		if (!location.transaction) {
			history.push("/");
		}
	}, []);

	const { error, document } = useDocument(
		`teams/${team}/positions`,
		location.id
	);

	console.log("use doc error", error);

	useEffect(() => {
		console.log("edit got a document", document);

		if (document && !isPending) {
			console.log("edit got a document", document.createdAt);
			setName(document.name);
			setAmount(location.transaction.detail.amount);
			setPrice(location.transaction.detail.price);
			setCreatedAt(
				moment(
					location.transaction.detail.createdDate
						.toDate()
						.toDateString()
				)
			);
		}
	}, [
		document,
		// location.transaction.detail.amount,
		// location.transaction.detail.createdDate,
		// location.transaction.detail.price,
		isPending,
	]);

	const handleDelete = async (e) => {
		console.log("lets delete");
		var owners = [];
		//	if document.owners.length > 1
		document.owners.forEach((item) => {
			console.log("delete itemfor each owner", item);
			if (
				item.createdDate.toString() ===
				location.transaction.detail.createdDate.toString()
			) {
				//	console.log("found a match", item);
				//owners.push(owner);
			} else {
				//	console.log("no match", item);
				owners.push(item);
			}
		});

		if (owners.length > 0) {
			console.log("found there are still owners");
			await updateDocument(location.id, {
				//owners: [...document.owners, owner],
				owners,
			});
		} else {
			console.log("there are no owners, can delete");
			await deleteDocument(location.id, {
				//owners: [...document.owners, owner],
				owners,
			});
		}

		console.log("owners", owners);

		// // save it to firebase

		console.log("edit document response", response);
		if (!response.error) {
			history.push("/");
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setFormError(null);
		setIsPending(true);

		const createdBy = {
			//	displayName: user.displayName,
			//photoURL: user.photoURL,
			id: location.transaction.detail.uid,
		};

		//	console.log("createdAt:", timestamp.fromDate(createdAt.toDate()));
		const owner = {
			//user: user.displayName,
			createdBy,
			price,
			amount,
			uid: location.transaction.detail.uid,
			createdDate: timestamp.fromDate(createdAt.toDate()),
			//	createdDate: timestamp.fromDate(new Date()),
		};

		var owners = [];

		document.owners.forEach((item) => {
			//	console.log("item", item.createdDate.toString());
			if (
				item.createdDate.toString() ===
				location.transaction.detail.createdDate.toString()
			) {
				//	console.log("found a match", item);
				owners.push(owner);
			} else {
				//	console.log("no match", item);
				owners.push(item);
			}
		});

		console.log("owners", owners);

		await updateDocument(location.id, {
			//owners: [...document.owners, owner],
			owners,
		});

		// // save it to firebase

		console.log("edit document response", response);
		if (!response.error) {
			history.push("/");
		}
	};

	return (
		<div className="create-form">
			<h2 className="page-title">Edit Position</h2>
			<form onSubmit={handleSubmit}>
				<label>
					<span>Symbol:</span>
					<input
						disabled
						type="text"
						//	onChange={(e) => setName(e.target.value)}
						value={name}
					/>
				</label>
				<label>
					<span>Amount:</span>
					<input
						required
						type="number"
						onChange={(e) => setAmount(e.target.value)}
						value={amount}
					/>
				</label>
				<label>
					<span>Price per Share:</span>
					<input
						required
						type="number"
						onChange={(e) => setPrice(e.target.value)}
						value={price}
					/>
				</label>

				<SingleDatePicker
					date={createdAt}
					onDateChange={onDateChange}
					focused={calendarFocused}
					onFocusChange={onFocusChange}
					numberOfMonths={1}
					isOutsideRange={() => false}
				/>

				{/* <label>
          <span>Assign to:</span>
          <Select
            options={users}
            onChange={(option) => setAssignedUsers(option)}
            isMulti
          />
        </label> */}

				<button className="btn">Save</button>
				<button
					type="button"
					className="btn"
					onClick={() => history.push("/")}
				>
					Cancel
				</button>
				<button type="button" className="btn" onClick={handleDelete}>
					Delete
				</button>

				{formError && <p className="error">{formError}</p>}
			</form>
		</div>
	);
}
