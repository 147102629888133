import { useReducer, useEffect, useState } from "react";

// we use this object everytime we want to interact with firestore
import { projectFirestore, timestamp } from "../firebase/config";

let initalState = {
	document: null,
	isPending: false,
	error: null,
	success: null,
};

const positionReducer = (state, action) => {
	switch (action.type) {
		case "IS_PENDING":
			return {
				success: false,
				isPending: true,
				error: null,
				document: null,
			};

		case "ADDED_POSITION":
			return {
				success: true,
				isPending: false,
				error: null,
				document: action.payload,
			};
		case "DELETED_DOCUMENT":
			return {
				isPending: false,
				document: null,
				success: true,
				error: null,
			};
		case "UPDATED_DOCUMENT":
			return {
				isPending: false,
				document: action.payload,
				success: true,
				error: null,
			};
		case "ERROR":
			return {
				success: false,
				isPending: false,
				error: action.payload,
				document: null,
			};
		default:
			return state;
	}
};

export const usePosition = (team) => {
	//console.log("use position props", team);
	const [response, dispatch] = useReducer(positionReducer, initalState);
	const [isCanceled, setIsCanceled] = useState(false);
	// console.log("useFirestore collection", collection, uid, subcollection);

	//collection ref - original. Now we've divided it to allow for sub collections.
	// const ref = projectFirestore.collection(collection);
	const teamref = projectFirestore.collection(`teams/${team}/positions/`);
	const positionsref = projectFirestore.collection(`positions`);

	// if (id && subcollection) {
	// 	// console.log("inside the if!");
	// 	ref = projectFirestore.collection(
	// 		`${collection}/${id}/${subcollection}`
	// 	);
	// 	ref = projectFirestore
	// 		.collection(collection)
	// 		.doc(id)
	// 		.collection(subcollection);
	// } else {
	// 	//console.log("the else");
	// 	ref = projectFirestore.collection(collection);
	// }
	// console.log("our ref!!", ref);

	// only dispatch if not canceled
	const dispatchIfNotCanceled = (action) => {
		if (!isCanceled) {
			dispatch(action);
		}
	};

	// add document
	const addPosition = async (doc) => {
		dispatch({ type: "IS_PENDING" });

		//	console.log("USEPOSITION let's add position", doc);

		// add to positions collection
		try {
			console.log("USEPOSITION adding to positions", doc.name);

			const usersRef = projectFirestore
				.collection("positions")
				.doc(doc.name);

			//tryting to use await inside then block
			let snapshot = await usersRef.get();
			if (snapshot.exists) {
				usersRef.onSnapshot((doc) => {
					// do stuff with the data
					console.log("doc exists! ");
				});
			} else {
				console.log("doc does not exist!");
				const addedPosition = positionsref
					.doc(doc.name.toUpperCase())
					.set({ name: doc.name.toUpperCase() });
				dispatchIfNotCanceled({
					type: "ADDED_POSITION",
					payload: addedPosition,
				});
			}
			// get current
			async function getCurrentPrice() {
				//for (const symbols of positionsObject) {
				//	let ourPositionsString = symbols.toString();
				//	console.log("position string", ourPositionsString);
				const API_Call = `${process.env.REACT_APP_CLOUD_IEXAPIS}/stable/stock/market/batch?symbols=${doc.name}&types=quote&token=${process.env.REACT_APP_CLOUD_API_KEY}`;
				//	console.log("our api call", API_Call);
				const fetchResponse = await fetch(API_Call);
				const json = await fetchResponse.json();
				//	console.log("got some json", json);
				//	console.log("keys", Object.keys(json));

				// let's write to the DB!
				Object.entries(json).forEach(([key, value]) => {
					let sector = value.quote.sector
						? value.quote.sector
						: "none";

					let longDescription = value.quote.longDescription
						? value.quote.longDescription
						: "none";

					let industry = value.quote.industry
						? value.quote.industry
						: "none";
					let exchange = value.quote.exchange
						? value.quote.exchange
						: "none";
					let securityType = value.quote.securityType
						? value.quote.securityType
						: "none";
					let name = value.quote.name ? value.quote.name : "none";
					let high = value.quote.high ? value.quote.high : "none";
					let low = value.quote.low ? value.quote.low : "none";
					let avgTotalVolume = value.quote.avgTotalVolume
						? value.quote.avgTotalVolume
						: "none";

					projectFirestore
						.collection("positions")
						.doc(key)
						.set({
							latestPrice: value.quote.latestPrice,
							latestUpdate: value.quote.latestUpdate,
							change: value.quote.change,
							avgTotalVolume,
							changePercent: value.quote.changePercent,
							sector,
							exchange,
							name,
							securityType,
							high,
							low,
							industry,
							longDescription,
						})
						.then(() => {
							console.log("Document successfully written!");
						})
						.catch((error) => {
							console.error("Error writing document: ", error);
						});

					console.log(key, " - ", value.quote.latestPrice); // key - value
				});
				//}
			}
			getCurrentPrice();

			// add to teams collection
			try {
				console.log("USEPOSITION adding to teams", doc);
				const createdAt = timestamp.fromDate(new Date());
				const addedPosition = await teamref.add({ ...doc, createdAt });
				dispatchIfNotCanceled({
					type: "ADDED_DOCUMENT",
					payload: addedPosition,
				});
			} catch (err) {
				console.log("ERROR", err);
				dispatchIfNotCanceled({ type: "ERROR", payload: err.message });
			}

			// end get curent

			// usersRef.get().then((docSnapshot) => {
			// 	if (docSnapshot.exists) {
			// 		usersRef.onSnapshot((doc) => {
			// 			// do stuff with the data
			// 			console.log("doc exists! ")
			// 		});
			// 	} else {
			// 		console.log("doc does not exist!");
			// 		// usersRef.set({...}) // create the document
			// 	}
			// });
		} catch (err) {
			console.log("ERROR addding to positions", err);
			dispatchIfNotCanceled({ type: "ERROR", payload: err.message });
		}
	};

	// delete document
	const deletePosition = async (id) => {
		dispatch({ type: "IS_PENDING" });
		try {
			await teamref.doc(id).delete();
			dispatchIfNotCanceled({
				type: "DELETED_DOCUMENT",
			});
		} catch (err) {
			dispatchIfNotCanceled({
				type: "ERROR",
				payload: "could not delete",
			});
		}
	};

	//update documents
	const updatePosition = async (id, updates) => {
		dispatch({ type: "IS_PENDING" });

		try {
			const updatedDocument = await teamref.doc(id).update(updates);
			dispatchIfNotCanceled({
				type: "UPDATED_DOCUMENT",
				payload: updatedDocument,
			});
			return updatedDocument;
		} catch (err) {
			dispatchIfNotCanceled({ type: "ERROR", payload: err.message });
			return null;
		}
	};

	useEffect(() => {
		return () => setIsCanceled(true);
	}, []);

	return { addPosition, deletePosition, updatePosition, response };
};
