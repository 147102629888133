import { useState } from "react";
// import Avatar from "../../components/Avatar";
import { timestamp } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useFirestore } from "../../hooks/useFirestore";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

export default function PositionComments({ position, team }) {
	const { updateDocument, response } = useFirestore(
		"teams",
		team,
		"positions"
	);
	const [newComment, setNewComemnt] = useState("");
	const { user } = useAuthContext();

	console.log("position:", position);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const commentToAdd = {
			displayName: user.displayName,
			photoURL: user.photoURL,
			content: newComment,
			createdAt: timestamp.fromDate(new Date()),
			id: Math.random(),
		};

		console.log("comment to add", commentToAdd);
		console.log("comment position id", position.id);
		await updateDocument(position.id, {
			comments: [...position.comments, commentToAdd],
		});
		console.log("comment response", response);

		if (!response.error) {
			setNewComemnt("");
		}
	};
	return (
		<div className="project-comments">
			<h4>Comments</h4>

			<ul>
				{position.comments.length > 0 &&
					position.comments.map((comment) => (
						<li key={comment.id}>
							<div className="comment-author">
								{/* update this later from team mates  */}
								{/* <Avatar src={comment.photoURL} /> */}
								<p>{comment.displayName}</p>
							</div>
							<div className="comment-date">
								<p>
									{formatDistanceToNow(
										comment.createdAt.toDate(),
										{
											addSuffix: true,
										}
									)}
								</p>
							</div>
							<div className="comment-content">
								<p>{comment.content}</p>
							</div>
						</li>
					))}
			</ul>
			<form className="add-comment" onSubmit={handleSubmit}>
				<label>
					<span>Add new comment:</span>
					<textarea
						requried="true"
						onChange={(e) => setNewComemnt(e.target.value)}
						value={newComment}
					></textarea>
				</label>
				<button className="btn">Add Comment</button>
			</form>
		</div>
	);
}
