//components
import { useCollection } from "../hooks/useCollection";
import Avatar from "./Avatar";

export default function OnlineUsers({ team, teamMates }) {
	//const { error, documents } = useCollection("users");
	const { error, documents } = useCollection(`teams/${team}/users/`);

	return (
		<div className="user-list">
			<h2>{team}</h2>
			{error && <div className="error">{error}</div>}
			{documents &&
				document &&
				documents
					.filter((item) => item.team === team)
					.map((user) => {
						// console.log("online users", user);
						// console.log("online users team", teamMates);
						return (
							<div key={user.id} className="user-list-item">
								{user.online && (
									<span className="online-user"></span>
								)}
								<span>{user.displayName}</span>
								<Avatar uid={user.id} />

								{/* <Avatar src={user.photoURL} /> */}
							</div>
						);
					})}
		</div>
	);
}
