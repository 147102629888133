import { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import numeral from "numeral";
// import moment from "moment";

// Components
import Table from "./Tables/Table";
// import Avatar from "../components/Avatar";
import { mainColumns } from "./Tables/Columns.js";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icons
import { FaPlus } from "react-icons/fa";
// import { FaSort } from "react-icons/fa";
// import { FaSortUp } from "react-icons/fa";
// import { FaSortDown } from "react-icons/fa";
// import { BiWrench } from "react-icons/bi";

//import { renderToStaticMarkup as rtsm } from 'react-dom/server';

//import styled, { css } from 'styled-components';

export const PositionsTable = ({ positions, teamMates }) => {
	// const [hiddenColumnsState, setHiddenColumnsState] = useState([
	// 	"Position",
	// 	"Latest",
	// ]);
	const mobile = useMediaQuery("(max-width:600px)");
	const tablet = useMediaQuery("(max-width:800px)");

	// useEffect(() => {
	// 	console.log("matches mobile", mobile);
	// 	mobile && setHiddenColumnsState(["Position", "AvgCost"]);
	// }, [mobile]);

	// useEffect(() => {
	// 	console.log("matches tablet", tablet);
	// }, [tablet]);

	// const [calcPositions, setCalcPositions] = useState();
	//	console.log("table columnand test", myColumns);
	// console.log("table team mates", teamMates);

	// }).filter((number) => number > 60);
	let filteredPostions = positions.filter((item) => item.latestPrice);
	// let invalidPositions = positions.filter((item) => !item.latestPrice);
	// console.log("filtered positions", filteredPostions);
	// console.log("bad positions", invalidPositions);

	// //Main Columns

	const columns = useMemo(() => mainColumns, []);
	// const MobileColumns = useMemo(() => myMobileColumns, []);

	// console.log("hidden state", hiddenColumnsState);
	// console.log("my hidden state", myHidden);

	return (
		<div className="table-container padding-top">
			{positions.length === 0 ? (
				<span className="">
					No positions...
					{/* <img src="https://snipcart.com/media/9862/snipcart-404.gif" alt="404" /> */}
				</span>
			) : (
				// props.expenses.map((expense) => {
				//   // console.log("mapping in expense list", expense);
				//   return <ExpenseListItem key={expense.id} {...expense} />;
				// })
				// <Styles>
				<>
					{/* <div className="hide-mobile"> */}

					{!mobile && !tablet && (
						<Table
							teamMates={teamMates}
							columns={columns}
							data={filteredPostions}
							hiddenColumns={[]}
						/>
					)}
					{!mobile && tablet && (
						<Table
							teamMates={teamMates}
							columns={columns}
							data={filteredPostions}
							hiddenColumns={[
								// "Amount",
								// "AvgCost",
								"Latest",
								"Updated",
								"InitialValue",
								"CurrentValue",
								"gainLoss",
							]}
						/>
					)}
					{mobile && tablet && (
						<Table
							teamMates={teamMates}
							columns={columns}
							data={filteredPostions}
							hiddenColumns={[
								"Amount",
								"AvgCost",
								"Latest",
								"Updated",
								"InitialValue",
								"CurrentValue",
								"gainLoss",
							]}
						/>
					)}

					{/* <h2>Exceptions</h2> */}
					{/* <Table
							teamMates={teamMates}
							// columns={invalidColumns}
							data={invalidPositions}
							// renderRowSubComponent={renderRowSubComponent}
						/> */}
					{/* </div> */}
					{/* <div className="show-mobile">
						<Table
							teamMates={teamMates}
							columns={MobileColumns}
							data={filteredPostions}
							//	renderRowSubComponent={renderRowSubComponent}
						/>
					</div> */}
				</>
				// </Styles>
			)}
			<div className="page-header__actions">
				<Link className="button add_position" to="/create">
					<FaPlus />
				</Link>
			</div>
		</div>
	);
};

export default PositionsTable;
