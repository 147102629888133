import { useState } from "react";
import { useCollection } from "./../hooks/useCollection";
import { useFirestore } from "./../hooks/useFirestore";

//components

export default function AddUsers({ user, team }) {
	const { documents } = useCollection("approvedUsers");

	const { addDocument, response } = useFirestore("approvedUsers");
	const [email, setEmail] = useState("");
	const [formError, setFormError] = useState(null);

	const handleSubmit = async (e) => {
		if (!team) {
			return;
		}

		e.preventDefault();
		setFormError(null);

		const data = {
			email,
			team: team,
		};

		if (documents)
			if (documents.find((x) => x.email === email && x.team === team)) {
				setFormError(`User ${email} already exists for ${team}`);
			} else {
				await addDocument(data);
				console.log("add document response", response);

				if (!response.error) {
					console.log("no error!");
					setEmail("");
				}
			}
	};

	return (
		<div className="user-list">
			<h4>Add Users</h4>
			<form onSubmit={handleSubmit}>
				<label>
					<span>email:</span>
					<input
						required
						type="email"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
					/>
				</label>

				<div className="control">
					{response.isPending && (
						<button disabled className="btn">
							Pending
						</button>
					)}
					{!response.isPending && (
						<button className="btn">Add User</button>
					)}
					{formError && <p className="error">{formError}</p>}
				</div>
			</form>
		</div>
	);
}
