import { NavLink } from "react-router-dom";
// import Avatar from "./Avatar";
import { useAuthContext } from "../hooks/useAuthContext";

import { Link } from "react-router-dom";
import OnlineUsers from "./OnlineUsers";
import AddUsers from "./AddUsers";
import ApprovedUsers from "./approvedUsers";
import { useLogout } from "../hooks/useLogout";
import Avatar from "./Avatar";

// styles & images

import DashboardIcon from "../assets/dashboard_icon.svg";
import AddIcon from "../assets/add_icon.svg";

export default function Sidebar({ team, sidebarIsVisible, teamMates, user }) {
	//const { user } = useAuthContext();
	const { logout, isPending } = useLogout();

	return (
		<div
			className={`sidebar-right user-sidebar ${
				sidebarIsVisible ? "visible" : "hidden"
			}`}
		>
			<div className="user">
				{/* <div className="control">
					<h3>Sidebar</h3>
				</div> */}
				<Avatar uid={user.uid} name={user.displayName} />

				<p className="gray">{user.email}</p>
				<i>{team}</i>
			</div>

			<div className="buttonWrapper">
				<Link className="btn" to="/profile">
					Edit Profile
				</Link>
				<button className="btn" onClick={logout}>
					Logout
				</button>
			</div>
		</div>
	);
}
