import numeral from "numeral";
import Number from "../../components/Number";

import { useEffect, useRef, useState } from "react";

// numbers
import { usePrevious } from "../../hooks";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
// import AnimatedNumber from "animated-number-react";
import AnimatedNumber from "animated-number-react";
import AnimatingNumber from "../../components/animating-number";

export default function TeamSummary({ positions }) {
	//	let validPostions = positions.filter((item) => item.latestPrice);

	let totalTeamValue = positions.reduce(
		(sum, p) => sum + parseFloat(p.currentTotalValue),
		0
	);
	let totalInitialValue = positions.reduce(
		(sum, p) => sum + parseFloat(p.initialTotalValue),
		0
	);

	let totalTeamAmount = positions.reduce(
		(sum, p) => sum + parseFloat(p.totalAmount),
		0
	);

	let totalGainLoss = positions.reduce(
		(sum, p) => sum + parseFloat(p.gainLoss),
		0
	);

	let todayTodayChange = positions.reduce(
		(sum, p) => sum + parseFloat(p.totalTodayChange),
		0
	);
	// in summary: percentage increase = increase ÷ original number ×
	// 100.
	let totalGainLossPercent = totalGainLoss / totalInitialValue;
	let todayGainLossPercent = todayTodayChange / totalTeamValue;
	// let totalAmount = doc.owners.reduce(
	// 	(sum, p) => sum + parseInt(p.amount),
	// 	0
	// );
	// doc.totalAmount = totalAmount;

	const expenseWord = totalTeamAmount === 1 ? "position" : "positions";

	const previousNumber = usePrevious(totalTeamValue);

	const usePreviousValue = (value) => {
		const ref = useRef();
		useEffect(() => {
			console.log("previous effect", value);
			ref.current = value;
		});
		return ref.current;
	};

	// const prevCount = usePreviousValue(totalTeamValue);

	// useEffect(() => {
	// 	console.log("previous total team value updating! ", prevCount);
	// 	return prevCount;
	// }, [totalTeamValue]);

	const prevCountRef = useRef();

	const [price, setPrice] = useState(0);
	const prevCount = usePrevious(price);

	useEffect(() => {
		//assign the ref's current value to the count Hook
		console.log("team summary loaded!!!");
		// setPrice(totalTeamValue);
		setPrice(totalTeamValue);
	}, [totalTeamValue]); //run this code when the value of count changes

	useEffect(() => {
		//assign the ref's current value to the count Hook
		console.log("seting price", price);
	}, []);

	return (
		<div className="team-summary">
			<h1 className="page-header__title">
				{/* <button onClick={() => setPrice(price + 10)}>MORE</button> */}
				Net Worth: <b>$</b>
				<CountUp
					start={prevCount}
					end={price}
					duration={1.75}
					separator=","
					decimals={2}
					onStart={() => {
						console.log("started!");
					}}
					onEnd={() => {
						console.log("ended!");
					}}
				/>
				{/* {numeral(price).format("0,0")} */}
				{/* <AnimatedNumbers includeComma animateToNumber={price} />
				<AnimatedNumber
					value={price}
					formatValue={(price) => price.toFixed(2)}
				/> */}
				{/* <AnimatedNumber
					value={price}
					formatValue={(v) => v.toFixed(0)}
					duration={1000}
				/>{" "} */}
				<br /> {/* Now: {price}, before: {prevCount} */}
				{/* Net Worth: Now: {totalTeamValue}, before: {prevCountRef.current} */}
				{/* <AnimatingNumber value={totalTeamValue} /> */}
				from{" "}
				{/* <span>{numeral(totalTeamValue).format("$0,0.000")}</span> from{" "} */}
				<span>{numeral(positions.length).format("0,0")}</span>{" "}
				{expenseWord} with{" "}
				<span>{numeral(totalTeamAmount).format("0,0.0")}</span> shares
			</h1>

			<h3>
				{" "}
				Total {totalGainLoss >= 0 ? " Gain" : " Loss"}:{" "}
				<Number value={totalGainLossPercent} percent={true} />{" "}
				<span className="small">
					{" "}
					<Number value={totalGainLoss} percent={false} />
				</span>
			</h3>

			<h3>
				Day Change:{" "}
				<Number value={todayGainLossPercent} percent={true} />{" "}
				<span className="small">
					<Number value={todayTodayChange} percent={false} />
				</span>
			</h3>
			<h3>
				{" "}
				Initial Invested Amount:{" "}
				{numeral(totalInitialValue).format("$0,0.000")}
			</h3>
		</div>
	);
}
