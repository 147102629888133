import { useState, useEffect, createContext } from "react";
import {
	BrowserRouter,
	Route,
	Switch,
	Redirect,
	// useParams,
} from "react-router-dom";
import { createCheckoutSession } from "../stripe/createCheckoutSection";
import { projectFirestore } from "../firebase/config";
import Loading from "./Loading";

// Components
// pages and components
import Dashboard from "../pages/dashboard/Dashboard";
// import Login from "../pages/login/Login";
// import Signup from "../pages/signup/Signup";
import Create from "../pages/create/Create";
import Position from "../pages/position/Position";
import Profile from "../pages/profiles/Profile";
import PageNotFound from "../pages/notfound/PageNotFound";
import EditPosition from "../pages/edit/EditPosition";
import Upgrade from "../pages/upgrade/Upgrade";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Settings from "./Settings";
import OnlineUsers from "./OnlineUsers";
import AddUsers from "./AddUsers";
import ApprovedUsers from "./approvedUsers";

// Hooks
import { useDocument } from "../hooks/useDocument";
import { invalid } from "moment";
import EditProfile from "./EditProfile";

export const TeamContext = createContext();

export default function TeamAuth({ user, userIsPremium }) {
	const [sidebarIsVisible, setSidebarIsVisible] = useState(false);
	const [settingsAreVisible, setSettingsAreVisible] = useState(false);
	const [prices, setPrices] = useState(null);
	const [team, setTeam] = useState(null);
	const [teamMates, setTeamMates] = useState(null);
	const [isPending, setIsPending] = useState(false);
	const [documents, setDocuments] = useState(null);
	const [ourResults, setOurResults] = useState(null);
	const [invalidPositions, setInvalidPositions] = useState(null);
	const [fetchError, setFetchError] = useState(null);
	console.log("fetch error", fetchError);

	const { error, document } = useDocument(`users/`, user.uid);
	console.log("use doc error", error);
	console.log("invalid positions", invalidPositions);

	// get positions.
	useEffect(() => {
		console.log("using effect one time team auth");
		let ref = projectFirestore.collection("positions");
		console.log("process.env", process.env.NODE_ENV);

		if (process.env.NODE_ENV === "development") {
			console.log("dev found!!");
		}

		const unsubscribe = ref.onSnapshot(
			(snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					results.push({ ...doc.data(), id: doc.id });
				});

				//	console.log("setting current prices!");
				// update state
				setPrices(results);
				setFetchError(null);
			},
			(error) => {
				console.log(error);
				setFetchError("could not fetch the data");
			}
		);

		// unsubscribe on unmount
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (document) {
			//	console.log("user doc", document);
			setTeam(document.team);
			//	console.log("effect team", team);
		}
	}, [document, team]);

	// get positions.
	useEffect(() => {
		console.log("documents or team changed, running effect");
		let ref = projectFirestore.collection(`teams/${team}/users/`);

		const unsubscribe = ref.onSnapshot(
			(snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					results.push({ ...doc.data(), id: doc.id });
				});

				// console.log("team mates results", results);

				setTeamMates(results);

				// update state

				setFetchError(null);
			},
			(error) => {
				console.log(error);
				setFetchError("could not fetch the data");
			}
		);

		// unsubscribe on unmount
		return () => unsubscribe();
	}, [team, documents]);

	useEffect(() => {
		console.log(
			"using effect for documents in team auth, prices and team dependency"
		);
		let ref = projectFirestore.collection(`teams/${team}/positions/`);

		const unsubscribe = ref.onSnapshot(
			(snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					results.push({ ...doc.data(), id: doc.id });
				});

				// looping through all positions
				results.forEach((doc) => {
					let currentPosition = prices.find((x) => x.id === doc.name);
					// console.log("current doc loop", doc);
					// console.log("current doc loop pos", currentPosition);

					if (!currentPosition) {
						return;
					}
					//	let currentPrice = prices.find((x) => x.id === doc.name);

					doc.latestPrice = currentPosition.latestPrice;
					doc.latestUpdate = currentPosition.latestUpdate;
					doc.avgTotalVolume = currentPosition.avgTotalVolume;
					doc.changePercent = currentPosition.changePercent;
					doc.timeStamp = currentPosition.timeStamp;

					doc.change = currentPosition.change;

					doc.exchange = currentPosition.exchange;
					doc.high = currentPosition.high;
					doc.low = currentPosition.low;
					doc.industry = currentPosition.industry;
					doc.longDescription = currentPosition.longDescription;
					// remove also from functions? see what actual data you are getting.
					//	doc.name = currentPosition.name;
					doc.sector = currentPosition.sector;
					doc.securityType = currentPosition.securityType;

					// total amount
					let totalAmount = doc.owners.reduce(
						(sum, p) => sum + parseFloat(p.amount),
						0
					);
					doc.totalAmount = totalAmount;
					doc.totalTodayChange = currentPosition.change * totalAmount;

					// initialValue/total cost
					doc.owners.forEach((owner) => {
						let total =
							parseFloat(owner.amount) * parseFloat(owner.price);
						owner.initialValue = total;
					});
					let initialValue = doc.owners.reduce(
						(sum, p) => sum + parseFloat(p.initialValue),
						0
					);
					doc.initialTotalValue = initialValue;

					// total value
					doc.currentTotalValue = doc.totalAmount * doc.latestPrice;

					// average cost
					// console.log(
					// 	doc.name,
					// 	"dividing ",
					// 	doc.initialTotalValue,
					// 	doc.totalAmount
					// );
					doc.averageCost = doc.initialTotalValue / doc.totalAmount;
					// console.log(
					// 	"dividing ",
					// 	doc.initialTotalValue,
					// 	doc.totalAmount,
					// 	"and getting",
					// 	doc.averageCost
					// );
					// gain/loss
					doc.gainLoss =
						doc.currentTotalValue - doc.initialTotalValue;

					// gain loss %
					doc.gainLossPercent = doc.gainLoss / doc.initialTotalValue;

					// making owner totals object (nested in positions object)
					let ownerTotals = [];
					console.log("MAKING OWNER TOTALS!");
					doc.owners.forEach((owner) => {
						// check to see if this owner exists already in our totals
						const found = ownerTotals.some(
							(el) => el.uid === owner.uid
						);

						if (!found) {
							// get total amount of shares purchased for each owner
							let totaledAmount = doc.owners
								.filter((item) => item.uid === owner.uid)
								.reduce((r, d) => r + parseFloat(d.amount), 0);

							// get total value of shares purchased for each owner
							let initalTotaledValue = doc.owners
								.filter((item) => item.uid === owner.uid)
								.reduce(
									(r, d) => r + parseFloat(d.initialValue),
									0
								);

							// average cost
							let averageCost =
								initalTotaledValue / totaledAmount;
							// total value
							let currentTotalValue =
								totaledAmount * doc.latestPrice;
							// gain/loss
							let gainLoss =
								currentTotalValue - initalTotaledValue;
							// gain loss %
							let gainLossPercent = gainLoss / initalTotaledValue;

							let latestPrice = doc.latestPrice;

							// today gain loss
							let todayGainLoss = totaledAmount * doc.change;

							let totalTodayChange = doc.change * totaledAmount;

							let todayGainLossPercent =
								todayGainLoss / currentTotalValue;

							ownerTotals.push({
								uid: owner.uid,
								totaledAmount: totaledAmount,
								initalTotaledValue: initalTotaledValue,
								averageCost: averageCost,
								currentTotalValue,
								gainLoss,
								gainLossPercent,
								name: doc.name,
								change: doc.change,
								changePercent: doc.changePercent,
								todayGainLoss,
								todayGainLossPercent,
								latestPrice,
								totalTodayChange,
							});
						}
					});

					doc.ownerTotals = ownerTotals;
				});

				//To calculate the average cost,
				//divide the total purchase amount  (initial Value ($2,750)
				//by the number of shares purchased (56.61)
				//to figure the average cost per share = $48.58.

				// let filteredPostions = results.filter(
				// 	//	(item) => item.latestPrice
				// 	(item) => !item.changePercent
				// );
				// let invalidPositions = results.filter(
				// 	//	(item) => !item.latestPrice
				// 	(item) => item.changePercent
				// );

				setDocuments(results);
				// setOurResults(results);
				// setDocuments(filteredPostions);
				// setInvalidPositions(invalidPositions);

				// setError(null);
			},
			(error) => {
				console.log(error);
				// setError("could not fetch the data");
			}
		);
		// unsubscribe on unmount
		return () => unsubscribe();
	}, [prices, team]);

	//	console.log("user is premium", userIsPremium);

	const handlePurchase = () => {
		setIsPending(true);
		createCheckoutSession(user.uid);
	};

	const toggleSidebar = (flag) => {
		console.log("clicked sidebar", flag);
		setSettingsAreVisible(false);

		flag === "off" ? setSidebarIsVisible(false) : setSidebarIsVisible(true);
	};

	const toggleSettings = (flag) => {
		console.log("clicked settings", flag);

		setSidebarIsVisible(false);

		flag === "off"
			? setSettingsAreVisible(false)
			: setSettingsAreVisible(true);
	};

	documents && console.log("our filtered", documents);
	invalidPositions && console.log("our invalid", invalidPositions);
	ourResults && console.log("our results", ourResults);

	return (
		<BrowserRouter>
			{!team && (
				<div>
					<Loading />
				</div>
			)}
			{team && documents && (
				<TeamContext.Provider value={teamMates}>
					<div className="container">
						<Header
							toggleSidebar={toggleSidebar}
							team={team}
							toggleSettings={toggleSettings}
							userIsPremium={userIsPremium}
							handlePurchase={handlePurchase}
						/>
						<div className="content-container">
							<Switch>
								<Route exact path="/">
									<Dashboard
										team={team}
										teamMates={teamMates}
										documents={documents}
									/>
									{!userIsPremium ? (
										isPending ? (
											<button className="btn" disabled>
												Loading...
											</button>
										) : (
											<button
												className="btn"
												onClick={() =>
													handlePurchase(user.uid)
												}
											>
												Upgrade to Premium
											</button>
										)
									) : (
										<h2>
											Have a cookie, premium customer!
										</h2>
									)}
								</Route>
								<Route path="/create">
									{user && team && (
										<Create user={user} team={team} />
									)}
								</Route>
								<Route path="/positions/:id">
									{
										<Position
											team={team}
											teamMates={teamMates}
											positions={documents}
										/>
									}
								</Route>
								<Route path="/team/:id">
									{
										<Profile
											positions={documents}
											teamMates={teamMates}
										/>
									}
								</Route>

								<Route path="/edit/:id">
									{<EditPosition team={team} />}
								</Route>
								<Route path="/login">
									<Redirect to="/" />
								</Route>
								<Route path="/signup">
									{<Redirect to="/" />}
								</Route>
								<Route path="/profile">
									<EditProfile user={user} team={team} />
								</Route>
								<Route path="/upgrade">
									<Upgrade user={user} team={team} />
								</Route>
								{/* <Route path="*"> {<Redirect to="/" />}</Route> */}
								<Route path="*" component={PageNotFound} />
							</Switch>
						</div>
					</div>

					<Sidebar
						teamMates={teamMates}
						sidebarIsVisible={sidebarIsVisible}
						team={team}
						user={user}
					/>
					<Settings
						teamMates={teamMates}
						settingsAreVisible={settingsAreVisible}
						team={team}
					/>
					{/* <div
						className={`sidebar-right ${
							sidebarIsVisible ? "visiible" : "hidden"
						}`}
					>
						{user && teamMates && (
							<OnlineUsers
								user={user}
								team={team}
								teamMates={teamMates}
							/>
						)}
						{user && <AddUsers user={user} team={team} />}
						{user && <ApprovedUsers team={team} />}
					</div> */}
				</TeamContext.Provider>
			)}
		</BrowserRouter>
	);
}
