import React from "react";
import numeral from "numeral";

export const Number = (props) => {
	let color = "black";
	// console.log("number", props.value, props.percent);
	if (props.value > 0) {
		color = "green";
	} else if (props.value < 0) {
		color = "red";
	} else {
		color = "black";
	}
	if (props.percent === true) {
		return (
			<span className={color}>
				{numeral(props.value).format("(%0,0.00)")}
			</span>
		);
	}
	if (props.color) {
		return (
			<span className={props.color}>
				{numeral(props.value).format("($0,0.00)")}
			</span>
		);
	} else
		return (
			<span className={color}>
				{numeral(props.value).format("$0,0.00")}
			</span>
		);
};

export default Number;
