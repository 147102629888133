import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import numeral from "numeral";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
	useTable,
	useGlobalFilter,
	useFilters,
	useAsyncDebounce,
	useSortBy,
	useExpanded,
	useRowSelect,
	matchSorter,
} from "react-table";

// Components
import Avatar from "../Avatar";
// import PositionComments from "../../pages/position/PositionComments";

function GlobalFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
}) {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);

	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="input-group">
			<div className="input-group__item">
				<input
					value={value || ""}
					onChange={(e) => {
						setValue(e.target.value);
						onChange(e.target.value);
					}}
					placeholder={`Search ${count} records...`}
					type="text"
				/>
			</div>
		</div>
	);
}

// Define a default UI for filtering
function DefaultColumnFilter({
	column: { filterValue, preFilteredRows, setFilter },
}) {
	const count = preFilteredRows.length;

	return (
		<input
			value={filterValue || ""}
			onChange={(e) => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			placeholder={`Search ${count} records...`}
		/>
	);
}

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// react component
export default function Table({
	data,
	teamMates,
	columns,
	clickToView,
	hiddenColumns,
}) {
	const { user } = useAuthContext();
	// console.log("table auth context", user);
	const history = useHistory();

	//	console.log("table data", data);

	const handleEdit = (user, row) => {
		// console.log("handle edit", user.createdDate.toString());
		// console.log("handle edit row?", row.original);
		// history.push(`/edit/${row.original.id}`);

		history.push({
			pathname: `/edit/${row.original.id}`,
			id: row.original.id,
			transaction: { detail: user },
		});
	};

	// here's the component that's inside the row
	const renderRowSubComponent = useCallback(
		({ row }) => {
			//	console.log("row", row);
			// console.log("sub component team mates", teamMates);
			//	console.log("row original", row.original);

			return (
				<div className="sub-items">
					<div className="col">
						<h3>Totals</h3>
						{row.original.ownerTotals &&
							row.original.ownerTotals.map((user, index) => {
								return (
									<div className="item" key={index}>
										<Avatar uid={user.uid} s name={true} />
										{" owns "}
										{user.totaledAmount} shares average at{" "}
										{numeral(user.averageCost).format(
											"$0,0.000"
										)}
										.{" "}
										{user.gainLossPercent > 0 ? (
											<span className="spacer">
												Gain:{" "}
											</span>
										) : (
											<span className="spacer">
												Loss:{" "}
											</span>
										)}
										<div
											className={
												user.gainLossPercent > 0
													? "green"
													: "red"
											}
										>
											{" "}
											{numeral(
												user.gainLossPercent
											).format("%0,0.000")}
										</div>
									</div>
								);
							})}
					</div>
					<div className="col">
						<h3>Activity</h3>

						{/* <b>{row.original.totalAmount}</b> */}
						{/* <p>
							get percentage user is up down. percentage owned.
							Percentage: initialTotalValue / total amount. loop
							through array and add inital values together where
							owners
						</p> */}
						{/* records.filter(({gender}) => gender === 'BOYS')
								.reduce((sum, record) => sum + record.value) */}

						{row.original.owners &&
							row.original.owners.map((owner, index) => {
								return (
									<div className="item" key={index}>
										<Avatar
											uid={owner.uid}
											teamMates={teamMates}
											name="true"
										/>
										{" bought "}
										{owner.amount} shares at ${owner.price}{" "}
										{" on "}
										{owner.createdDate
											.toDate()
											.toDateString()}
										{owner.uid === user.uid && (
											<button
												onClick={() =>
													handleEdit(owner, row)
												}
											>
												Edit
											</button>
										)}
									</div>
								);
							})}
					</div>

					<div className="col">
						<h3>Comments</h3>
						{/* // not updating correctly. but we don't really need to be able to comment here yet. click to detail view instead. */}
						{/* <ProjectComments
						position={row.original}
						teamMates={teamMates}
					/> */}

						{row.original.comments &&
							row.original.comments.map((comment) => {
								//	console.log("comment", comment);
								return (
									<div key={comment.id}>
										{comment.displayName} :{" "}
										{comment.content}{" "}
										<div className="comment-date">
											<p>
												{formatDistanceToNow(
													comment.createdAt.toDate(),
													{
														addSuffix: true,
													}
												)}
											</p>
										</div>
									</div>
								);
							})}
						<Link
							to={`/positions/${row.original.id}`}
							key={row.original.id}
						>
							View More
						</Link>
					</div>
				</div>
			);
		},
		[teamMates, user.uid]
	);

	const [filterState, setFilterState] = useState("hidden");
	function handleFilterToggle() {
		console.log("toggle");
		if (filterState === "hidden") {
			setFilterState("show");
		} else {
			setFilterState("hidden");
		}
	}

	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);
	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);
	// const [filterInput, setFilterInput] = useState("");
	// Use the useTable Hook to send the columns and data to build the table
	const {
		getTableProps, // table props from react-table
		getTableBodyProps, // table body props from react-table
		headerGroups, // headerGroups, if your table has groupings
		rows, // rows for the table based on the data passed
		prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
		visibleColumns,
		//	state: { expanded },
		// not working
		// onExpandedChange = (expanded, index, event) => {
		// 	console.log("epxnaded change!");
		// },
		state,

		preGlobalFilteredRows,
		setGlobalFilter,
		// allColumns,
		// getToggleHideAllColumnsProps,
		// selectedFlatRows,
		//state: { selectedRowIds },
		//	state: { selectedRowIds },
	} = useTable(
		{
			columns,
			data,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			initialState: { hiddenColumns: hiddenColumns },
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		useExpanded,
		useRowSelect,
		(hooks) => {}
	);

	// console.log("table data", data);
	// console.log("table data filter", rows);
	// console.log("table data rows values", Object.values(rows));

	// let totalAmount = Object.keys(rows).reduce(function (previous, key) {
	// 	return previous + rows[key].original.amount;
	// }, 0);
	// let totalValue = Object.keys(rows).reduce(function (previous, key) {
	// 	return previous + rows[key].original.currentValue;
	// }, 0);

	const handleRowClick = (row) => {
		console.log("row click", row);
		if (clickToView) {
			history.push(`/positions/${row.original.id}`);
		}
	};

	//console.log("clickToView", clickToView);
	return (
		<div className="table-wrapper">
			<div className="table-inner">
				<GlobalFilter
					preGlobalFilteredRows={preGlobalFilteredRows}
					globalFilter={state.globalFilter}
					setGlobalFilter={setGlobalFilter}
				/>
				<div className="table-info">
					<button
						className="filterToggle"
						onClick={handleFilterToggle}
					>
						{/* <FaFilter /> */}
					</button>
				</div>
			</div>

			<div className="inner-table-wrapper">
				{/* <input value={filterInput} onChange={handleFilterChange} placeholder={"Search name"} /> */}
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th
										{...column.getHeaderProps()}
										className={
											column.isSorted
												? column.isSortedDesc
													? "sort-desc"
													: "sort-asc"
												: ""
										}
									>
										<div
											{...column.getHeaderProps(
												column.getSortByToggleProps()
											)}
											className="col-title"
										>
											{" "}
											{column.render("Header")}
										</div>
										<div
											className={`filterArea ${filterState}`}
										>
											{/* <div className={`filterArea`}> */}
											{column.canFilter
												? column.render("Filter")
												: null}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								// Use a React.Fragment here so the table markup is still valid

								<React.Fragment key={i}>
									<tr
										{...row.getRowProps()}
										{...row.getToggleRowExpandedProps()}
										className={`mainrow ${
											row.isExpanded
												? "expanded"
												: "collapsed"
										} ${i % 2 ? "even" : "odd"}`}
									>
										{row.cells.map((cell) => {
											return (
												//
												<td
													{...cell.getCellProps()}
													onClick={() =>
														handleRowClick(row)
													}
												>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>
									{!clickToView && (
										<tr className="child">
											<td colSpan={visibleColumns.length}>
												{renderRowSubComponent({ row })}
											</td>
										</tr>
									)}
								</React.Fragment>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
}
