import { useEffect, useState } from "react";
import { projectFirestore } from "../../firebase/config";
import Number from "../../components/Number";

export default function Dow() {
	const [fetchError, setFetchError] = useState(null);
	const [prices, setPrices] = useState(null);
	useEffect(() => {
		let ref = projectFirestore.collection(`marketInfo/`);

		const unsubscribe = ref.onSnapshot(
			(snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					console.log("dow dow", doc);
					results.push({ ...doc.data(), id: doc.id });
				});

				setPrices(results);
				setFetchError(null);
			},
			(error) => {
				console.log(error);
				setFetchError("could not fetch the data");
			}
		);
		return () => unsubscribe();
	}, []);

	console.log("dow info", prices);

	return (
		<div>
			{prices &&
				prices.map((position) => {
					// console.log("online users", user);
					// console.log("online users team", teamMates);
					return (
						<div key={position.id} className="market-index">
							<b>{position.shortName}</b>:{" "}
							<span>
								<Number
									value={position.regularMarketPrice}
									color="black"
								/>
							</span>{" "}
							<span>
								<Number
									value={position.regularMarketChange}
									percent={false}
								/>
							</span>{" "}
							<span>
								<Number
									value={
										position.regularMarketChangePercent /
										100
									}
									percent={true}
								/>
							</span>{" "}
							{position.marketState === "REGULAR"
								? "OPEN"
								: `${position.marketState} Market`}
						</div>
					);
				})}
		</div>
	);
}
