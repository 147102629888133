// import { useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "../components/Avatar";
// import { usePositionsPerUser } from "../hooks/usePositionsPerUser";
import numeral from "numeral";

// styles
import "./ProjectList.css";
import Number from "./Number";

export default function ProjectList({ positions, teamMates }) {
	//	const [projects, setProjects] = useState(positions);
	//console.log("positions list positions", positions);
	// const { documents, error } = usePositionsPerUser(teamMates, positions);

	//documents && console.log("project list docs", documents);

	console.log("card view postions", positions);

	return (
		<div className="project-list">
			{positions.length === 0 && <p>No positions yet!</p>}
			{positions.map((position) => {
				return (
					<Link to={`/positions/${position.id}`} key={position.id}>
						<h4>{position.name}</h4>
						{position.gainLossPercent > 0 && "Gain: "}
						{position.gainLossPercent < 0 && "Loss: "}
						<Number
							value={position.gainLossPercent}
							percent={true}
						/>
						<br />
						<p>
							Average Price: {position.averageCost} Amount:{" "}
							{position.totalAmount} Current:
							{position.latestPrice}
						</p>
						<p>
							Created at{" "}
							{position.createdAt.toDate().toDateString()}
						</p>
						<div className="assigned-to">
							<p>
								<strong>Owned by:</strong>
							</p>
							<ul>
								{position.ownerTotals.map((user, index) => (
									<li key={index}>
										{/* <li key={user.createdBy.photoURL}> */}
										<Avatar
											uid={user.uid}
											teamMates={teamMates}
											name={false}
										/>
										{/* {" owns "}
										{/* <Avatar src={user.createdBy.photoURL} /> */}
										{/* {user.totaledAmount} shares at{" "}
										{numeral(user.averageCost).format(
											"($0,0.00)"
										)}{" "}
										average per share.
										<div>
											{user.gainLossPercent > 0
												? "Gain:"
												: "Loss:"}
											<div
												className={
													user.gainLossPercent > 0
														? "green"
														: "red"
												}
											>
												{numeral(
													user.gainLossPercent
												).format("(%0,0.000)")} */}{" "}
										{/* </div> */}
										{/* </div> */}
										{/* <p className="small">
									
										</p> */}
									</li>
								))}
							</ul>
						</div>
					</Link>
				);
			})}
		</div>
	);
}
