// import { useState } from "react";
import numeral from "numeral";
import { Link } from "react-router-dom";
import { NULL } from "sass";
import Number from "../../components/Number";

// hooks
import { usePositionsPerUser } from "../../hooks/usePositionsPerUser";

export const TeamDisplay = ({ positions, teamMates }) => {
	// const [teamMatesState, setTeamMatesState] = useState(null);
	const { documents } = usePositionsPerUser(teamMates, positions);

	//const { documents, error } = usePostionCollection(team);
	//let validPostions = positions.filter((item) => item.latestPrice);

	//	console.log("team mates here", teamMates);
	// getting all team mates positions.
	// should we use affect this and see if it changes?
	// const teamMatesState = teamMates.map((mate) => {
	// 	console.log("state id", mate);
	// 	let ownedDocuments = [];
	// 	positions.forEach((doc) => {
	// 		let docFound = doc.ownerTotals.find(
	// 			({ uid: docUid }) => docUid === mate.id
	// 		);
	// 		if (docFound) {
	// 			console.log("doc found", docFound);
	// 			ownedDocuments.push({
	// 				name: doc.name,
	// 				totaledAmount: docFound.totaledAmount,
	// 				initalTotaledValue: docFound.initalTotaledValue,
	// 				currentTotalValue: docFound.currentTotalValue,
	// 				gainLoss: docFound.gainLoss,
	// 				gainLossPercent: docFound.gainLossPercent,
	// 				averageCost: docFound.averageCost,
	// 				todayGainLoss: docFound.todayGainLoss,
	// 				change: docFound.change,
	// 			});
	// 		}
	// 	});

	// 	return {
	// 		id: mate.id,
	// 		displayName: mate.displayName,
	// 		email: mate.email,
	// 		photoURL: mate.photoURL,
	// 		team: mate.team,
	// 		online: mate.online,
	// 		positions: ownedDocuments,
	// 	};
	// });

	//console.log("our docs", documents);
	//	setTeamMatesState(res);
	//console.log("team mate state", teamMatesState);

	return (
		<div className="profiles">
			<div className="profile-layout">
				{documents &&
					documents.map((member) => {
						// const result = validPostions.map((item) => ({
						// 	...item,

						// 	// ownerTotals: item.ownerTotals.filter(
						// 	// 	(owner) => owner.uid === member.id
						// 	// ),
						// }));

						//	console.log("memberInfo Result", result);
						// let memberInfo = [];

						// result.forEach((position) => {
						// 	if (
						// 		position.ownerTotals[0] &&
						// 		position.ownerTotals[0].uid
						// 	) {
						// 		memberInfo.push({
						// 			name: position.name,
						// 			change: position.change,
						// 			owner: position.ownerTotals[0].uid,
						// 			initalTotaledValue:
						// 				position.ownerTotals[0].initalTotaledValue,
						// 			gainLoss: position.ownerTotals[0].gainLoss,
						// 			currentTotalValue:
						// 				position.ownerTotals[0].currentTotalValue,
						// 		});
						// 	}
						// });
						//
						// result.map((position) =>
						// 	(memberInfo.name = position.name)(
						// 		(memberInfo.owner = position.ownerTotals[0].uid)
						// 	)
						// );

						//	console.log("memberInfo for", member.displayName, memberInfo);

						//	console.log("my loops", result);

						// console.log("member info", memberInfo);

						// let totalGainLoss = validPostions.reduce(
						// 	(sum, p) =>
						// 		sum +
						// 		p.ownerTotals.filter((owner) => owner.uid === member.id)
						// 			.gainLoss,
						// 	0
						// );
						//	console.log("total gain loss", totalGainLoss);

						// let  gainLoss = member.positions.reduce(
						// 	(tmp, { displayName, gainLoss }) =>
						// 		displayName === name ? tmp + gainLoss : tmp,
						// 	0
						// )

						let gainLoss = member.positions.reduce(
							(prevValue, currentValue) =>
								prevValue + currentValue.gainLoss,
							0
						);

						let todayGainLoss = member.positions.reduce(
							(prevValue, currentValue) =>
								prevValue + currentValue.todayGainLoss,
							0
						);

						console.log("member positions", member.positions);

						let initialTotalValue = member.positions.reduce(
							(prevValue, currentValue) =>
								prevValue + currentValue.initialTotalValue,
							0
						);

						let currentTotalValue = member.positions.reduce(
							(prevValue, currentValue) =>
								prevValue + currentValue.currentTotalValue,
							0
						);

						console.log(
							"gain los divide",
							gainLoss,
							"/",
							initialTotalValue
						);
						let gainLossPercent = gainLoss / initialTotalValue;

						//	total value / change in value
						// console.log(
						// 	"curent total and today",
						// 	currentTotalValue,
						// 	todayGainLoss
						// );

						//let todayGainLossPercent;
						console.log(
							"divide percent ",
							currentTotalValue,
							"/",
							todayGainLoss
						);
						let todayGainLossPercent =
							todayGainLoss / currentTotalValue;

						// console.log(
						// 	"divide percent ",
						// 	currentTotalValue,
						// 	"/",
						// 	todayGainLoss,
						// 	"=",
						// 	todayGainLossPercent
						// );
						// let todayGainLossPercent;
						// // we canot devide by 0
						// if (currentTotalValue > 0 && todayGainLoss > 0) {
						// 	todayGainLossPercent =
						// 		todayGainLoss / currentTotalValue;
						// } else {
						// 	todayGainLossPercent = 0;
						// }

						return (
							<Link
								to={`/team/${member.displayName}`}
								key={member.id}
								className={` profile-wrapper`}
							>
								{/* <div key={member.id} className={` profile-wrapper`}> */}
								<div
									className="card-layout__box "
									key={member.id}
								>
									<div className="photo">
										<div className="photoWrapper">
											{/* ccurrent total value {currentTotalValue} */}
											<img
												src={member.photoURL}
												alt={member.displayName}
												width="100"
											/>
										</div>
										<div className="member-name">
											<b>{member.displayName}</b>
										</div>
									</div>
									<div className="info">
										<div className="sub-info">
											Total
											{gainLoss >= 0 ? " Gain" : " Loss"}
											<div className="number">
												<Number
													value={gainLossPercent}
													percent={true}
												/>
											</div>
											<div>
												<span className="small">
													<Number
														value={gainLoss}
														percent={false}
													/>
												</span>
											</div>
										</div>
										<div className="sub-info today-info">
											Today:
											<div className="number">
												<Number
													value={todayGainLossPercent}
													percent={true}
												/>
											</div>
											<div className={`small`}>
												<Number
													value={todayGainLoss}
													percent={false}
												/>
											</div>
										</div>
									</div>
								</div>
							</Link>
						);
					})}
			</div>
		</div>
	);
};

export default TeamDisplay;
