import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import usePremiumStatus from "./hooks/usePremiumStatus";

//styles
import "./App.css";

// pages and components
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";

import TeamAuth from "./components/TeamAuth";

function App() {
	const { user, authIsReady } = useAuthContext();

	console.log("my user", user);
	const userIsPremium = usePremiumStatus(user);
	console.log("user is premium", userIsPremium);

	return (
		<div className="App">
			{authIsReady && !user && (
				<BrowserRouter>
					<div className="container">
						<Switch>
							<Route exact path="/">
								<Redirect to="/login" />
							</Route>{" "}
							<Route path="/login">
								<Login />
							</Route>{" "}
							<Route path="/signup">{!user && <Signup />}</Route>
							<Route path="/create">
								<Signup />
							</Route>
							<Route path="/positions/:id">
								<Redirect to="/login" />
							</Route>
							<Route path="/team/:id">
								<Redirect to="/login" />
							</Route>
							<Route path="/edit/:id">
								<Redirect to="/login" />
							</Route>
						</Switch>
					</div>
				</BrowserRouter>
			)}

			{user && <TeamAuth user={user} userIsPremium={userIsPremium} />}
		</div>
	);
}

export default App;
